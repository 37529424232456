// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { I18n } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import loftJapan from '../../assets/img/LoftJapan.png';
import awsloft from '../../assets/img/LoftIconTokyo.png';

const styles = theme => ({
  root: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    marginBottom: '40px',
    backgroundColor: theme.palette.background.secondary
  },
  button: {
    borderRadius: '28px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '15px',
    color: '#FFFFFF',
    letterSpacing: '2.4px',
    width: '311px',
    height: '56px'
  },
  textFieldContainer: {
    marginLeft: '20px',
    marginRight: '20px'
  },
  textField: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #E0E4E8',
    borderRadius: '6.4px'
  },
  textFieldInput: {
    color: '#000000'
  },
  wrapper: {
    position: 'relative'
  },
  topImage: {
    maxWidth: 'min(420px, 100vw)',
    maxHeight: '142px'
  },
  buttonProgress: {
    color: '#ffffff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

type Props = {
  classes: Object,
  loading: boolean,
  customForgotPassword: () => void,
  handleInputChange: () => void,
  userName: string
};

const ForgotPassword = ({
  classes,
  loading = false,
  customForgotPassword = () => {},
  handleInputChange = () => {},
  userName = ''
}: Props) => (
  <div
    className={classes.root}
    onKeyPress={ev => ev.key === 'Enter' && customForgotPassword()}
  >
    <Grid container spacing={24} justify="center">
      <Grid container item justify="center">
        <img
          src={loftJapan}
          alt="AWS Startup Loft Tokyo"
          className={classes.topImage}
        />
      </Grid>
      <Grid container item justify="center">
        <img
          src={awsloft}
          height="63"
          width="262"
          alt="AWS Startup Loft Tokyo"
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.textFieldContainer}>
          <Typography variant="h5" align="left">
            {I18n.get('common').email}
          </Typography>
          <TextField
            name="username"
            label="Email"
            className={classes.textField}
            InputProps={{
              classes: {
                input: classes.textFieldInput
              }
            }}
            type="email"
            margin="normal"
            variant="filled"
            fullWidth
            defaultValue={userName}
            onChange={handleInputChange}
            color="secondary"
          />
        </div>
      </Grid>
      <Grid item>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={loading}
            onClick={() => {
              customForgotPassword();
            }}
          >
            {I18n.get('forgotPassword').sendCode}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(ForgotPassword);
