import { Auth } from 'aws-amplify';
// eslint-disable-next-line camelcase
const aws_exports = {
  API: {
    aws_appsync_authenticationType:
      process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
    aws_appsync_graphqlEndpoint:
      process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
    aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
    endpoints: [
      {
        name: 'events',
        endpoint: process.env.REACT_APP_ENDPOINTS_EVENTS_ENDPOINT
      },
      {
        name: 'loft',
        endpoint: process.env.REACT_APP_ENDPOINTS_LOFT_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken
          };
        }
      },
      {
        name: 'user',
        endpoint: process.env.REACT_APP_ENDPOINTS_USER_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken
          };
        }
      },
      {
        name: 'no_auth_user',
        endpoint: process.env.REACT_APP_ENDPOINTS_USER_ENDPOINT
      },
      {
        name: 'no_auth_loft',
        endpoint: process.env.REACT_APP_ENDPOINTS_LOFT_ENDPOINT
      }
    ]
  },
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID
  }
  // Analytics: {
  //   // OPTIONAL - disable Analytics if true
  //   disabled: false,
  //   // OPTIONAL - Allow recording session events. Default is true.
  //   autoSessionRecord: true,

  //   AWSPinpoint: {
  //     // OPTIONAL -  Amazon Pinpoint App Client ID
  //     appId: 'c5b48694e6b84ee4be126f89e497f59c',
  //     // OPTIONAL -  Amazon service region
  //     region: 'us-east-1'
  //   }
  // }
};
// eslint-disable-next-line camelcase
export default aws_exports;
