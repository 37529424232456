// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import { Divider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    marginTop: '20px',
    backgroundColor: theme.palette.background.secondary
  },
  information: {
    marginTop: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  iframeWrapper: {
    overflow: 'hidden',
    width: '100%',
    height: '300px'
  },
  iframe: {
    border: 0,
    marginTop: '-150px'
  }
});

type Props = {
  classes: Object
};

const Access = ({ classes }: Props) => (
  <div className={classes.root}>
    <div className={classes.iframeWrapper}>
      <iframe
        title="map"
        width="100%"
        height="600px"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.7690813353797!2d139.71448761519943!3d35.63341884020016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b1eb0408f6f%3A0x8ab69bebf4bdbcd8!2z44CSMTQxLTAwMjEg5p2x5Lqs6YO95ZOB5bed5Yy65LiK5aSn5bSO77yT5LiB55uu77yR4oiS77yR!5e0!3m2!1sja!2sjp!4v1555920287081!5m2!1sja!2sjp"
        frameBorder="0"
        allowFullScreen
        className={classes.iframe}
      ></iframe>
    </div>
    <div className={classes.information}>
      <Typography variant="h5" gutterBottom>
        {I18n.get('access').location}
      </Typography>
      <Typography variant="body1">
        {I18n.get('access').addressDetail}
      </Typography>
      <Typography variant="body1">{I18n.get('access').accessDetail}</Typography>
      <Divider className={classes.divider} />
      <Typography variant="h5" gutterBottom>
        {I18n.get('access').loftBusinessHours}
      </Typography>
      <Typography variant="body1">{I18n.get('access').openHours}</Typography>
      <Typography variant="body1">{I18n.get('access').closeHours}</Typography>
      <Typography variant="body1">
        {I18n.get('access').finalEntranceTime}
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="h5" gutterBottom>
        {I18n.get('access').aaeBusinessHours}
      </Typography>
      <Typography variant="body1">{I18n.get('access').openHours}</Typography>
      <Typography variant="body1">{I18n.get('access').closeHours}</Typography>
      <Typography variant="body1">
        {I18n.get('access').finalConsultationTime}
      </Typography>
    </div>
  </div>
);

export default withStyles(styles)(Access);
