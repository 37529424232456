// @flow
import moment from 'moment-timezone';

moment.tz.setDefault('Asia/Tokyo');
moment.locale('ja', {
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
});

const DateUtil = {
  createJpnDateStr: (unixTime: number): string => {
    return moment.unix(unixTime).format('MM月DD日 (ddd)');
  },
  createEngDateStr: (unixTime: number): string => {
    return moment.unix(unixTime).format('MM/DD (ddd)');
  },
  getDateUnixTime: (addDays: number = 0, unixTime: ?number = null): number => {
    if (unixTime === null) {
      return moment()
        .add(addDays, 'days')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .unix();
    } else {
      return moment
        .unix(unixTime)
        .add(addDays, 'days')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .unix();
    }
  },
  getCurrentUnixTime: (): number => {
    return moment().unix();
  },
  getHHMM: (unixTime: number): string => {
    return moment.unix(unixTime).format('HH:mm');
  },
  isWeekend: (unixTime: number): boolean => {
    const day = moment.unix(unixTime).day();
    return day === 0 || day === 6;
  }
};
export default DateUtil;
