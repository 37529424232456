// @flow
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import CheckIn from './CheckIn';
import Approval from './Approval';
import Welcome from '../../components/top/Welcome';
import Announcement from '../common/Announcement';
import Error from '../../components/common/Error';
import Loading from '../../components/common/Loading';
import UserSession from '../../stores/UserSession';
import * as serviceWorker from '../../serviceWorker';

type Props = {
  userSession: UserSession
};

export const useTop = (userSession: UserSession) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    // NOTE: PWAのキャッシュのクリアをしている。
    // これを外すとホーム画面にアプリを配置したときにリリースが反映されなくなる
    serviceWorker.refreshCache();
  }, []);
  userSession
    .syncAuthorizationUser()
    .then(result => {
      setLoading(false);
    })
    .catch(error => {
      console.log(error);
      setError(true);
    });
  return { loading, error };
};

const TopContainer = inject('userSession')(
  observer(({ userSession }: Props) => {
    const { loading, error } = useTop(userSession);
    if (loading) return <Loading />;
    if (error) return <Error />;
    return (
      <>
        {userSession.isAuthenticated && userSession.isApprovedUser && (
          <CheckIn />
        )}
        {userSession.isAuthenticated && !userSession.isApprovedUser && (
          <Approval />
        )}
        {!userSession.isAuthenticated && <Welcome />}
        <Announcement />
      </>
    );
  })
);

export default TopContainer;
