// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { I18n } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import loftJapan from '../../assets/img/LoftJapan.png';
import awsloft from '../../assets/img/LoftIconTokyo.png';
import PasswordInput from '../common/PasswordInput';
import { validator } from '../../utils/Validator';

const styles = theme => ({
  root: {
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundColor: theme.palette.background.secondary
  },
  button: {
    borderRadius: '28px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '15px',
    color: '#FFFFFF',
    letterSpacing: '2.4px',
    width: '311px',
    height: '56px',
    marginTop: '20px'
  },
  textFieldLabel: {
    paddingLeft: '5px',
    paddingRight: '20px',
    marginBottom: '10px'
  },
  textFieldContainer: {
    marginLeft: '20px',
    marginRight: '20px'
  },
  textField: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #E0E4E8',
    borderRadius: '6.4px'
  },
  textFieldInput: {
    color: '#000000'
  },
  validatorForm: {
    width: '100%',
    marginBottom: '60px'
  },
  topImage: {
    maxWidth: 'min(420px, 100vw)',
    maxHeight: '142px'
  },
  buttonProgress: {
    color: '#ffffff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: -12
  },
  wrapper: {
    position: 'relative'
  }
});

type Props = {
  classes: Object,
  loading: boolean,
  customCompletePassword: () => void,
  handleInputChange: () => void,
  userName: string,
  password: string,
  confirmationPassword: string,
  code: string
};

const CompletePassword = ({
  classes,
  loading = false,
  customCompletePassword = () => {},
  handleInputChange = () => {},
  userName = '',
  password = '',
  confirmationPassword = '',
  code = ''
}: Props) => (
  <div className={classes.root}>
    <Grid container spacing={24} justify="center">
      <Grid container item justify="center">
        <img
          src={loftJapan}
          alt="AWS Startup Loft Tokyo"
          className={classes.topImage}
        />
      </Grid>
      <Grid container item justify="center">
        <img
          src={awsloft}
          height="63"
          width="262"
          alt="AWS Startup Loft Tokyo"
        />
      </Grid>
      <Grid item>
        <ValidatorForm
          onSubmit={customCompletePassword}
          className={classes.validatorForm}
        >
          <Grid container spacing={24} justify="center">
            <Grid item xs={12}>
              <div className={classes.textFieldContainer}>
                <Typography variant="h5" align="left">
                  {I18n.get('common').email}
                </Typography>
                <TextValidator
                  name="username"
                  label="email"
                  value={userName}
                  fullWidth
                  className={classes.textField}
                  InputProps={{
                    classes: {
                      input: classes.textFieldInput
                    }
                  }}
                  disabled={true}
                  margin="normal"
                  variant="filled"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.textFieldContainer}>
                <Typography variant="h5" align="left">
                  {I18n.get('forgotPassword').newPassword}
                </Typography>
                <PasswordInput
                  name={'password'}
                  handleInputChange={handleInputChange}
                  value={password}
                  label={I18n.get('common').alphaNumericSymbol}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.textFieldContainer}>
                <Typography variant="h5" align="left">
                  {I18n.get('forgotPassword').confirmPassword}
                </Typography>
                <PasswordInput
                  name={'confirmationPassword'}
                  handleInputChange={handleInputChange}
                  value={confirmationPassword}
                  label={I18n.get('common').alphaNumericSymbol}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.textFieldContainer}>
                <Typography variant="h5" align="left">
                  {I18n.get('common').code}
                </Typography>
                <TextValidator
                  name="code"
                  type="number"
                  value={code}
                  label={I18n.get('common').numeric}
                  fullWidth
                  onChange={handleInputChange}
                  className={classes.textField}
                  InputProps={{
                    classes: {
                      input: classes.textFieldInput
                    }
                  }}
                  validators={validator('code').validationItems}
                  errorMessages={validator('code').validationMessages}
                  margin="normal"
                  variant="filled"
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  className={classes.button}
                  disabled={loading}
                >
                  {I18n.get('forgotPassword').change}
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(CompletePassword);
