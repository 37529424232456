// @flow
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { I18n } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const styles = theme => ({
  root: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '10px',
    backgroundColor: theme.palette.background.secondary,
    minHeight: '100vh'
  },
  errorIcon: {
    color: '#E10050'
  }
});

type Props = {
  classes: Object
};

const NotFound = (props: Props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={8}
        justify="center"
        direction="row"
        alignItems="center"
      >
        <Grid item>
          <ErrorOutlineIcon className={classes.errorIcon} />
        </Grid>
        <Grid item>
          <Typography variant="h5">{I18n.get('error').notFound}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(NotFound);
