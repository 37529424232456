// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import type { SlotStatus } from '../../type/aae';
import SlotRemainButton from './SlotRemainButton';
import { Button, Grid, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  classes: Object,
  status: SlotStatus,
  time: string,
  type: string,
  eventName: string,
  description: string,
  remain: number,
  detail: string,
  isInputVisible: boolean,
  setDetailInputId: number => void,
  setIsDialogOpen: boolean => void,
  handleInputChange: () => void
};

const ReservationSlot = (props: Props) => {
  const {
    classes,
    status = 'active',
    time = '',
    type = 'aae',
    eventName = '',
    description = '',
    remain = 1,
    detail = '',
    isInputVisible = false,
    setDetailInputId = number => {},
    setIsDialogOpen = boolean => {},
    handleInputChange = () => {}
  } = props;
  return (
    <Grid container justify="center">
      <Grid item align="center" xs={12}>
        <SlotRemainButton
          status={status}
          onClickFn={setDetailInputId}
          remain={remain}
          time={time}
          type={type}
          isFocused={isInputVisible}
          eventName={eventName}
          description={description}
        ></SlotRemainButton>
      </Grid>
      <Grid item align="center" xs={12}>
        {isInputVisible && (
          <div className={classes.textWrapper}>
            <TextField
              placeholder={I18n.get('reserve').consultationContent}
              multiline
              rows="10"
              value={detail}
              variant="outlined"
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.textFieldInput
                }
              }}
              className={classes.textArea}
              onChange={handleInputChange}
            />
            <Grid container justify="flex-end">
              <Button
                variant="contained"
                color="secondary"
                disabled={detail === ''}
                className={classes.reserveButton}
                onClick={() => setIsDialogOpen(true)}
              >
                {I18n.get('reserve').confirm}
              </Button>
            </Grid>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  textWrapper: {
    margin: '0px 20px 0px 20px',
    color: '#fff',
    maxWidth: '880px'
  },
  textArea: {
    marginTop: '10px',
    width: '100%',
    backgroundColor: '#fff'
  },
  textFieldInput: {
    color: '#000',
    backgroundColor: '#fff'
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderStyle: 'none'
    }
  },
  cssFocused: {
    borderStyle: 'none'
  },
  notchedOutline: {
    borderStyle: 'none'
  },
  reserveButton: {
    borderRadius: '14px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '12px',
    letterSpacing: '2.4px',
    width: '100%',
    height: '30px',
    marginTop: '10px',
    marginBottom: '10px',
    maxWidth: '120px'
  }
});

export default withStyles(styles)(ReservationSlot);
