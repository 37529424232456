// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Expansion from '../common/Expansion';

const styles = theme => ({
  root: {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '10px',
    backgroundColor: '#FFF',
    minHeight: '100vh'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  body: {
    color: '#283C50'
  },
  faqs: {
    marginTop: '20px'
  },
  title: {
    color: '#283C50'
  }
});

type Props = {
  classes: Object,
  faqs: Object
};

const Faq = (props: Props) => {
  const { classes, faqs } = props;
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h1">
        {I18n.get('faq').faq}
      </Typography>
      <div className={classes.faqs}>
        {faqs.map((faq, index) => {
          return (
            <Expansion
              key={index}
              title={faq.question}
              description={faq.answer}
              data-testid={`faq${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default withStyles(styles)(Faq);
