// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { I18n } from 'aws-amplify';

const styles = theme => ({
  root: {
    paddingTop: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: 'inherit'
  },
  loading: {
    marginTop: '40px'
  },
  text: {
    color: 'inherit'
  },
  progress: {
    color: 'inherit',
    marginTop: -12,
    marginLeft: -12
  }
});

type Props = {
  classes: Object,
  size: string,
  text?: String
};

// TODO: Function Component への変更
class Loading extends Component<Props> {
  render() {
    const { classes, size, text = I18n.get('common').loading } = this.props;

    if (size === 'small') {
      return (
        <div className={classes.root}>
          <div id="loading" className={classes.loading}>
            <Grid
              container
              spacing={24}
              justify="center"
              direction="column"
              alignItems="center"
            >
              <Grid item>
                <CircularProgress className={classes.progress} />
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div id="loading" className={classes.loading}>
          <Grid
            container
            spacing={8}
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <CircularProgress className={classes.progress} />
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.text}>
                {text}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Loading);
