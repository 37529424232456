// @flow
import React, { Component } from 'react';
import { AppBar, Toolbar, IconButton, SvgIcon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const styles = {
  loftCloseIcon: {
    fill: '#F2941B',
    fontSize: '28px'
  },
  appBar: {
    backgroundColor: '#FFF',
    boxShadow: 'none',
    margin: '0 auto',
    maxWidth: '1024px',
    left: 0
  },
  appBarBlack: {
    backgroundColor: '#232F3E',
    boxShadow: 'none',
    margin: '0 auto',
    maxWidth: '1024px',
    left: 0
  },
  padding: {
    backgroundColor: 'inherit',
    paddingTop: '60px'
  },
  toolBar: {
    paddingLeft: '0px'
  },
  container: {
    margin: '0 auto',
    maxWidth: '1024px'
  }
};

function LoftCloseIcon(props) {
  const svgpath = 'M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z';
  return (
    <SvgIcon {...props} style={styles.loftCloseIcon}>
      <path d={svgpath}></path>
    </SvgIcon>
  );
}

type Props = {};
type State = {
  isWhite: boolean
};
export const withCloseNavigationBar = (
  ComposedComponent: any,
  options: { isWhite: boolean } = { isWhite: true }
) =>
  class extends Component<Props, State> {
    changeColor(options: { isWhite: boolean } = { isWhite: true }) {
      this.setState(options);
    }

    componentWillMount() {
      this.setState(options);
    }

    render() {
      return (
        <div style={styles.container}>
          <AppBar
            position="fixed"
            style={this.state.isWhite ? styles.appBar : styles.appBarBlack}
          >
            {this.state.isWhite && (
              <Helmet>
                <style type="text/css">
                  {` 
                    body { 
                      background-color: #FFF; 
                      color: #000; 
                    } 
                  `}
                </style>
              </Helmet>
            )}
            {!this.state.isWhite && (
              <Helmet>
                <style type="text/css">
                  {` 
                    body { 
                      background-color: #232F3E; 
                      color: #FFF; 
                    } 
                  `}
                </style>
              </Helmet>
            )}
            <Toolbar style={styles.toolBar}>
              <IconButton
                color="inherit"
                aria-label="menu"
                component={Link}
                to="/"
              >
                <LoftCloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div style={styles.padding}></div>
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  };
