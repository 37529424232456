import React from 'react';
import { Link } from '@material-ui/core';
export const formatString = (string, values) => {
  // eslint-disable-next-line no-unused-vars
  for (const i in values) {
    if ({}.hasOwnProperty.call(values, i)) {
      string = string.replace(`\$\{${i}\}`, values[i]);
    }
  }
  return string;
};

export const formatReturnToHtml = string => {
  if (!string) return string;
  return string.split('\n').map((str, index) => (
    <span key={index}>
      {str}
      <br />
    </span>
  ));
};

export const formatLinkToHtml = (string, urlList) => {
  if (!string) return string;
  // NOTE: Safari breaks lookbehind RegExp.
  // See https://caniuse.com/js-regexp-lookbehind
  const stringList = string
    .split(/<\/a>/)
    .map((v, i, arr) => (i < arr.length - 1 ? (v += '</a>') : v));
  const result = [];
  // eslint-disable-next-line no-unused-vars
  for (const [i, url] of urlList.entries()) {
    const linkText = stringList[i].split(/<a>(.+)<\/a>/);
    result.push(
      <React.Fragment key={i}>
        {linkText[0]}
        <Link href={url} color="secondary" underline="always" target="_blank">
          {linkText[1]}
        </Link>
      </React.Fragment>
    );
  }
  const lastIndex = stringList.length - 1;
  result.push(
    <React.Fragment key={lastIndex}>{stringList[lastIndex]}</React.Fragment>
  );
  return result;
};
