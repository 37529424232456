// @flow
import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Header from '../../components/header/Header';
import TopViewTab from '../../stores/TopViewTab';
import UserSession from '../../stores/UserSession';

type Props = {
  topViewTab: TopViewTab,
  userSession: UserSession
};

export const useHeader = (userSession: UserSession, topViewTab: TopViewTab) => {
  const [isLoading, setIsLoading] = useState(false);
  const selectTab = (tabId: number) => {
    topViewTab.setSelected(tabId);
  };
  const selectedTabId = topViewTab.selected;
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await userSession.syncAuthorizationUser();
      setIsLoading(false);
    })();
  }, []);
  // userSession.syncAuthorizationUser();
  const isAuthenticated = userSession.isAuthenticated;
  let userNameFirstChar = '';
  if (isAuthenticated) {
    userNameFirstChar = `${userSession.givenName} ${userSession.familyName}`
      .slice(0, 1)
      .toUpperCase();
  }
  return {
    selectTab,
    selectedTabId,
    isAuthenticated,
    userNameFirstChar,
    isLoading
  };
};

const HeaderContainer = inject('userSession', 'topViewTab')(
  observer(({ userSession, topViewTab }: Props) => {
    return <Header {...useHeader(userSession, topViewTab)} />;
  })
);

export default HeaderContainer;
