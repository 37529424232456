// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { LoftEventIcon } from '../common/LoftSvgIcon';

const styles = theme => ({
  root: {
    marginTop: '20px',
    paddingTop: '170px',
    paddingBottom: '170px',
    height: '100%',
    backgroundColor: theme.palette.background.secondary
  },
  largeIcon: {
    width: '90px',
    height: '90px',
    color: '#B4BEC8'
  },
  description: {
    paddingTop: '13px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '13px'
  },
  footer: {
    height: '40px'
  }
});

type Props = {
  classes: Object
};

const EmptyEvent = ({ classes }: Props) => (
  <div data-testid="event" className={classes.root}>
    <Grid container spacing={16}>
      <Grid item xs={12} direction="column" alignItems="center" container>
        <LoftEventIcon className={classes.largeIcon} />
      </Grid>
      <Grid item xs={12} direction="column" alignItems="center" container>
        <Typography
          className={classes.description}
          variant="body1"
          align="center"
        >
          {I18n.get('event').empty}
        </Typography>
      </Grid>
      <Grid
        item
        className={classes.footer}
        xs={12}
        direction="column"
        alignItems="center"
        container
      ></Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(EmptyEvent);
