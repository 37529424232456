// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import { Grid, Typography } from '@material-ui/core';
import { TextValidator } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';
import { validator } from '../../utils/Validator';

const styles = theme => ({
  textLabel: {
    margin: '20px 4px 10px 4px'
  },
  textField: {
    marginRight: '2px',
    marginLeft: '2px',
    border: '1px solid #E0E4E8',
    borderRadius: '6.4px',
    fontFamily: 'HiraKakuProN-W3',
    fontSize: '14px'
  },
  disabled: {
    opacity: 0.5
  },
  textFieldInput: {
    color: '#283C50',
    paddingLeft: '8px',
    letterSpacing: '1.2px',
    height: '56px',
    backgroundColor: '#F4F6F8'
  }
});

type Props = {
  classes: Object,
  disabled: boolean,
  fieldOnly: boolean,
  name: string,
  type: string,
  value: string,
  placeholder: string,
  handleInputChange: any => void
};

const ProfileInput = ({
  classes,
  disabled = false,
  fieldOnly = false,
  name = '',
  type = 'text',
  value = '',
  placeholder = '',
  handleInputChange = (e: any) => {}
}: Props) => (
  <>
    {fieldOnly ? (
      <TextValidator
        name={name}
        value={String(value)}
        placeholder={String(placeholder)}
        type={type}
        fullWidth
        onChange={handleInputChange}
        className={
          disabled
            ? classes.textField + ' ' + classes.disabled
            : classes.textField
        }
        InputProps={{
          classes: {
            input: classes.textFieldInput
          }
        }}
        disabled={disabled}
        validators={validator(name).validationItems}
        errorMessages={validator(name).validationMessages}
      />
    ) : (
      <Grid item xs={12} justify="flex-start" color="primary" container>
        <Typography
          variant="h5"
          color="primary"
          gutterBottom
          className={classes.textLabel}
        >
          {I18n.get('common')[name]}
        </Typography>
        <TextValidator
          name={name}
          value={String(value)}
          placeholder={String(placeholder)}
          type={type}
          fullWidth
          onChange={handleInputChange}
          className={
            disabled
              ? classes.textField + ' ' + classes.disabled
              : classes.textField
          }
          InputProps={{
            classes: {
              input: classes.textFieldInput
            }
          }}
          disabled={disabled}
          validators={validator(name).validationItems}
          errorMessages={validator(name).validationMessages}
        />
      </Grid>
    )}
  </>
);

export default withStyles(styles)(ProfileInput);
