// @flow
import { action, observable } from 'mobx';
import cookies from 'js-cookie';

import { persistence, StorageAdapter } from 'mobx-persist-store';

function readStore(name) {
  return new Promise(resolve => {
    const data = cookies.get(name);
    // const data = localStorage.getItem(name);
    resolve(data);
  });
}
function writeStore(name, content) {
  return new Promise(resolve => {
    cookies.set(name, content, { expires: 1, sameSite: 'lax' });
    // localStorage.setItem(name, content);
    resolve();
  });
}

@persistence({
  name: 'SignUpFormValue',
  properties: [
    'email',
    'givenName',
    'familyName',
    'startupRelationship',
    'companyName',
    'accountId',
    'zipcode',
    'jobRole',
    'country',
    'companyScale',
    'industry',
    'consumption',
    'jobPosition',
    'phone',
    'developerState',
    'privacyCheck',
    'ruleCheck',
    'credentialCheck'
  ],
  adapter: new StorageAdapter({
    read: readStore,
    write: writeStore
  })
})
/* eslint no-invalid-this: 0 */
class SignUpFormValue {
  @observable email: string = '';
  @observable givenName: string = '';
  @observable familyName: string = '';
  @observable startupRelationship: string = '';
  @observable companyName: string = '';
  @observable accountId: string = '';
  @observable zipcode: string = '';
  @observable jobRole: string = '';
  @observable country: string = '';
  @observable companyScale: string = '';
  @observable industry: string = '';
  @observable consumption: string = '';
  @observable jobPosition: string = '';
  @observable phone: string = '';
  @observable developerState: string = '';
  @observable password: string = '';
  @observable confirmPassword: string = '';
  @observable privacyCheck: boolean = false;
  @observable ruleCheck: boolean = false;
  @observable credentialCheck: boolean = false;

  @action.bound
  setEmail = (v: string) => {
    this.email = v;
  };
  @action.bound
  setGivenName = (v: string) => {
    this.givenName = v;
  };
  @action.bound
  setFamilyName = (v: string) => {
    this.familyName = v;
  };
  @action.bound
  setStartupRelationship = (v: string) => {
    this.startupRelationship = v;
  };
  @action.bound
  setCompanyName = (v: string) => {
    this.companyName = v;
  };
  @action.bound
  setAccountId = (v: string) => {
    this.accountId = v;
  };
  @action.bound
  setZipcode = (v: string) => {
    this.zipcode = v;
  };
  @action.bound
  setJobRole = (v: string) => {
    this.jobRole = v;
  };
  @action.bound
  setCountry = (v: string) => {
    this.country = v;
  };
  @action.bound
  setCompanyScale = (v: string) => {
    this.companyScale = v;
  };
  @action.bound
  setIndustry = (v: string) => {
    this.industry = v;
  };
  @action.bound
  setConsumption = (v: string) => {
    this.consumption = v;
  };
  @action.bound
  setJobPosition = (v: string) => {
    this.jobPosition = v;
  };
  @action.bound
  setPhone = (v: string) => {
    this.phone = v;
  };
  @action.bound
  setDeveloperState = (v: string) => {
    this.developerState = v;
  };
  @action.bound
  setPassword = (v: string) => {
    this.password = v;
  };
  @action.bound
  setConfirmPassword = (v: string) => {
    this.confirmPassword = v;
  };
  @action.bound
  setPrivacyCheck = (v: boolean) => {
    this.privacyCheck = v;
  };
  @action.bound
  setRuleCheck = (v: boolean) => {
    this.ruleCheck = v;
  };
  @action.bound
  setCredentialCheck = (v: boolean) => {
    this.credentialCheck = v;
  };

  @action.bound
  initialize = () => {
    this.setEmail('');
    this.setGivenName('');
    this.setFamilyName('');
    this.setStartupRelationship('');
    this.setCompanyName('');
    this.setAccountId('');
    this.setZipcode('');
    this.setJobRole('');
    this.setCountry('');
    this.setCompanyScale('');
    this.setIndustry('');
    this.setConsumption('');
    this.setJobPosition('');
    this.setPhone('');
    this.setDeveloperState('');
    this.setPassword('');
    this.setConfirmPassword('');
    this.setPrivacyCheck(false);
    this.setRuleCheck(false);
    this.setCredentialCheck(false);
  };
}

export default SignUpFormValue;
