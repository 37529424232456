// @flow
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { inject, observer } from 'mobx-react';
import SignOut from '../../components/signout/SignOut';
import SignOutDialog from '../../components/signout/SignOutDialog';
import UserSession from '../../stores/UserSession';
import TopViewTab from '../../stores/TopViewTab';

type Props = {
  userSession: UserSession,
  topViewTab: TopViewTab
};

export const useSignOut = (
  userSession: UserSession,
  topViewTab: TopViewTab
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const doSignOut = () => {
    setIsOpen(false);
    Auth.signOut()
      .then(() => {
        userSession.initialize();
      })
      .catch(err => console.log(err));
    topViewTab.setSelected(0);
  };
  return { isOpen, setIsOpen, doSignOut };
};

const SignOutContainer = inject('userSession', 'topViewTab')(
  observer(({ userSession, topViewTab }: Props) => {
    const { isOpen, setIsOpen, doSignOut } = useSignOut(
      userSession,
      topViewTab
    );
    return (
      <>
        <SignOut setIsOpen={setIsOpen} />
        <SignOutDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          doSignOut={doSignOut}
        />
      </>
    );
  })
);

export default SignOutContainer;
