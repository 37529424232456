// @flow
import React from 'react';
import { withStyles } from '@material-ui/core';
import { Grid, Hidden, Link, Typography } from '@material-ui/core';
import { I18n } from 'aws-amplify';
import { type RouterHistory } from 'react-router-dom';
import UserSession from '../../stores/UserSession';
import aae from '../../assets/img/AAE.jpg';
import { formatReturnToHtml } from '../../utils/FormatString';
import sideBar from '../../assets/img/SideBar.jpg';
import hajime from '../../assets/img/Hajime.png';

const styles = theme => ({
  root: {
    maxWidth: '100%',
    paddingTop: '5px',
    marginBottom: '30px',
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundColor: theme.palette.background.secondary
  },
  lgHeight: {
    height: '100%'
  },
  mdHeight: {
    height: '850px'
  },
  sideBar: {
    position: 'relative',
    textAlign: 'center',
    backgroundColor: 'rgba(22, 30, 45, 0.8)',
    marginTop: '-2px'
  },
  sideBarTitle: {
    fontSize: 'min(4vw, 44px)',
    position: 'absolute',
    color: '#FFFFFF',
    top: '240px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    whiteSpace: 'nowrap',
    lineHeight: 1.5
  },
  sideBarImage: {
    width: '100%',
    opacity: 0.2,
    marginBottom: 0
  },
  sideBarCharacter: {
    position: 'absolute',
    top: '480px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    minWidth: '110px'
  },
  centerImage: {
    width: '100%',
    maxWidth: '680px'
  },
  contentArea: {
    marginBottom: '20px'
  },
  messageBox: {
    backgroundColor: '#141E30',
    padding: '10px 20px',
    borderRadius: '10px'
  },
  descriptionArea: {
    padding: '10px 30px'
  },
  button: {
    borderRadius: '28px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '15px',
    color: '#FFFFFF',
    letterSpacing: '2.4px',
    width: '311px',
    height: '56px',
    marginTop: '20px',
    marginBottom: '30px'
  },
  title: {
    marginTop: '20px',
    marginBottom: '10px',
    fontWeight: 'bold'
  }
});

type Props = {
  classes: Object,
  history: RouterHistory,
  userSession: UserSession
};

const AskAnExpert = (props: Props) => {
  const { classes, userSession } = props;

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="center">
        <Hidden xsDown>
          <Grid
            item
            sm={4}
            className={
              userSession.isAuthenticated && !userSession.isApprovedUser
                ? classes.sideBar + ' ' + classes.lgHeight
                : classes.sideBar + ' ' + classes.mdHeight
            }
          >
            <img
              src={sideBar}
              className={
                userSession.isAuthenticated && !userSession.isApprovedUser
                  ? classes.sideBarImage + ' ' + classes.lgHeight
                  : classes.sideBarImage + ' ' + classes.mdHeight
              }
            />
            <Typography variant="h1" className={classes.sideBarTitle}>
              {formatReturnToHtml(I18n.get('aae').sideBarTitle)}
            </Typography>
            <img src={hajime} className={classes.sideBarCharacter}></img>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={8} className={classes.contentArea}>
          <img src={aae} className={classes.centerImage} />
          <Grid container direction="row" justify="center">
            <Grid item xs={12} className={classes.title}>
              <Typography
                variant="h2"
                align="center"
                color="secondary"
                gutterBottom
              >
                {I18n.get('aae').descriptionTitle}
              </Typography>
              <Typography variant="h6" align="center">
                {I18n.get('aae').description1}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.messageBox}
              >
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom align="left">
                    オンライン相談 (Online Ask An Expert)
                    は当面の間休止いたします。再開の際はあらためてご案内をいたします。
                    <br />
                    <br />
                    AWS に関する技術的なご相談をご希望の際は、
                    <Link
                      href={'https://aws.amazon.com/jp/contact-us/'}
                      color="secondary"
                      underline="always"
                      target="_blank"
                    >
                      こちら
                    </Link>
                    の問い合わせページをご利用ください。
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(AskAnExpert);
