// @flow
import React, { Component } from 'react';
import { Auth, I18n } from 'aws-amplify';
import { withSnackbar } from 'notistack';
import { inject, observer } from 'mobx-react';
import ConfirmSignUp from '../../components/confirm-signup/ConfirmSignUp';
import { withCloseNavigationBar } from '../../components/common/CloseNavigationBar';
import { type RouterHistory } from 'react-router-dom';
import UserSession from '../../stores/UserSession';
import SignUpFormValue from '../../stores/SignUpFormValue';

// TODO: Function Component へ変更する

type Props = {
  history: RouterHistory,
  enqueueSnackbar: any,
  userSession: UserSession,
  signUpFormValue: SignUpFormValue
};

type State = {
  loading: boolean,
  code: string
};

@inject('userSession', 'signUpFormValue')
@observer
class ConfirmSignUpContainer extends Component<Props, State> {
  email = '';

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      code: ''
    };
    this.customConfirm = this.customConfirm.bind(this);
    this.resendCode = this.resendCode.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  customConfirm: () => void;
  customConfirm() {
    const { history, enqueueSnackbar } = this.props;

    this.setState({ loading: true });
    Auth.confirmSignUp(this.email, this.state.code)
      .then(() => {
        this.setState({ loading: false });
        enqueueSnackbar(I18n.get('confirmSignUp').success, {
          variant: 'success'
        });
        history.push('/signin');
      })
      .catch(err => {
        this.setState({ loading: false });
        const error = Object.prototype.hasOwnProperty.call(err, 'message')
          ? err.message
          : err;
        enqueueSnackbar(I18n.get(error), {
          variant: 'error'
        });
      });
  }

  resendCode: () => void;
  resendCode() {
    const { enqueueSnackbar } = this.props;
    this.setState({ loading: true });
    Auth.resendSignUp(this.email)
      .then(() => {
        enqueueSnackbar(this.email + I18n.get('confirmSignUp').sentCode, {
          variant: 'success'
        });
      })
      .catch(err => {
        const error = Object.prototype.hasOwnProperty.call(err, 'message')
          ? err.message
          : err;
        enqueueSnackbar(I18n.get(error), {
          variant: 'error'
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleInputChange: (e: any) => void;
  handleInputChange(e) {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  }

  render() {
    const { userSession, signUpFormValue } = this.props;
    this.email = userSession.email ? userSession.email : signUpFormValue.email;
    return (
      <ConfirmSignUp
        loading={this.state.loading}
        email={this.email}
        code={this.state.code}
        customConfirm={this.customConfirm}
        resendCode={this.resendCode}
        handleInputChange={this.handleInputChange}
      />
    );
  }
}

export default withCloseNavigationBar(withSnackbar(ConfirmSignUpContainer));
