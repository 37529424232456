// @flow
import { I18n } from 'aws-amplify';

const textValidationItems = {
  email: ['required', 'isEmail', 'maxStringLength:255'],
  password: [
    'required',
    'minStringLength:8',
    'maxStringLength:255',
    'matchRegexp:^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\\d)[a-zA-Z\\d!-~]{8,255}$'
  ],
  confirmPassword: [
    'required',
    'minStringLength:8',
    'maxStringLength:255',
    'matchRegexp:^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\\d)[a-zA-Z\\d!-~]{8,255}$'
  ],
  code: ['required', 'matchRegexp:^(?=.*?\\d)[\\d]{1,255}$'],
  familyName: ['required', 'minStringLength:1', 'maxStringLength:255'],
  givenName: ['required', 'minStringLength:1', 'maxStringLength:255'],
  companyName: ['required', 'minStringLength:1', 'maxStringLength:255'],
  phone: [
    'required',
    'minStringLength:9',
    'maxStringLength:15',
    'matchRegexp:^(?=.*?\\d)[\\d]{9,15}$'
  ],
  zipcode: [
    'required',
    'minStringLength:5',
    'maxStringLength:9',
    'matchRegexp:^(?=.*?\\d)[\\d]{5,9}$'
  ],
  jobPosition: ['required', 'minStringLength:1', 'maxStringLength:255'],
  accountId: ['required', 'matchRegexp:^(?=.*?\\d)[\\d]{12,12}$']
};

export const validator = (type: string) => {
  const items = type ? textValidationItems[type] : [];
  const textValidationMessages = {
    required: I18n.get('validation').required,
    isEmail: I18n.get('validation').isEmail,
    minStringLength: I18n.get('validation').minStringLength,
    maxStringLength: I18n.get('validation').maxStringLength,
    numeric: I18n.get('validation').numeric,
    containMultipleChars: I18n.get('validation').containMultipleChars,
    exactStringLength12: I18n.get('validation').exactStringLength12
  };
  const messages = [];
  items.forEach(item => {
    let message = '';
    if (item.indexOf('matchRegexp') !== -1) {
      // NOTE: 正規表現の場合のメッセージパターンを処理
      if (item.indexOf('^(?=.*?\\d)[\\d]') !== -1)
        message = textValidationMessages.numeric;
      if (item.indexOf('^(?=.*?[a-z])') !== -1)
        message = textValidationMessages.containMultipleChars;
      if (item.indexOf('^(?=.*?\\d)[\\d]{12,12}$') !== -1)
        message = textValidationMessages.exactStringLength12;
    } else if (item.indexOf('StringLength') !== -1) {
      // NOTE: 文字数の場合のメッセージパターンを処理
      const key = item.substring(0, item.indexOf(':'));
      const num = item.substring(item.indexOf(':') + 1);
      message = `${num}${textValidationMessages[key]}`;
    } else {
      message = textValidationMessages[item];
    }
    messages.push(message);
  });
  return {
    validationItems: items,
    validationMessages: messages
  };
};
