// @flow
export const dict = {
  ja: {
    lang: 'ja',
    common: {
      email: 'メールアドレス',
      password: 'パスワード (8 文字以上、大文字、小文字、数字をそれぞれ含む)',
      confirmPassword: 'もう一度パスワードを入力してください',
      name: '名前',
      familyName: '姓',
      givenName: '名',
      phone: '電話番号 (日中連絡が取れる番号をご記載ください)',
      zipcode: '郵便番号',
      companyName: '御社名',
      country: '国籍',
      companyScale: '御社の従業員数',
      startupRelationship: 'スタートアップとの関わり',
      developerState: 'アプリケーション開発に関わっていますか？',
      accountId: 'AWSアカウントID',
      jobPosition: '役職',
      jobRole: '職種',
      industry: '業種',
      consumption: 'AWSのご利用状況',
      code: '確認コード',
      alphaNumeric: '半角英数字',
      numeric: '半角数字',
      alphaNumericSymbol: '半角英数字および記号',
      noHyphen: '(ハイフンなし)',
      yes: 'はい',
      no: 'いいえ',
      choose: '選択してください',
      announcement: '<a>AWS Startup Loft Tokyo</a> からのお知らせ',
      error: 'エラーが発生しました',
      retryLater: '時間をおいて再度お試しください',
      loading: 'Now Loading...'
    },
    error: {
      notFound: 'ページが見つかりませんでした'
    },
    validation: {
      required: '必須項目です',
      isEmail: 'Eメールの形式が不正です',
      minStringLength: ' 文字以上で入力してください',
      maxStringLength: ' 文字以下で入力してください',
      numeric: '入力できるのは数字のみです',
      containMultipleChars:
        'パスワードは大文字、小文字、数字を含める必要があります',
      exactStringLength12: '12 文字で入力してください',
      chooseDeveloperState:
        '「アプリケーション開発に関わっていますか？」は必須入力です'
    },
    header: {
      signIn: 'サインイン',
      top: 'トップ',
      event: 'イベント',
      aae: 'オンライン相談',
      access: 'アクセス',
      others: 'その他'
    },
    home: {
      welcome: 'ようこそ、挑戦をカタチにする場所へ',
      signIn: 'サインイン',
      register: '今すぐ、無料登録',
      qrCode: 'あなたの QR コード',
      refresh: '画面を更新',
      currentlyClosed: '現在休館中です',
      checkInDescription1:
        'AWS Startup Loft Tokyo 受付のスキャナーにこちらの QR コードをかざしてください。',
      checkInDescription2:
        '(スクリーンショットや QR コードを印刷したものは入場時に無効となります。)',
      approvalDescription1:
        'AWS Startup Loft Tokyo の受付にて、本画面を「身分証」と一緒にご提示ください。',
      approvalDescription2:
        '(スクリーンショットや本画面を印刷したものは無効となりますのでご注意ください。)',
      description1:
        'AWS Startup Loft Tokyo は、スタートアップおよびデベロッパーのための施設です。',
      description2:
        '予約不要で無償で利用できるコ・ワーキングスペースをはじめ、テクニカルセミナー、ハンズオン、ミートアップや技術相談スペースなどをご提供しています。',
      descriptionDuringClosed1:
        'ウェビナーでテクニカルイベントを定期的に実施していますので、イベントタブをご覧ください。',
      descriptionDuringClosed2:
        'Online Ask an Expert の利用をご希望の方は、オンライン相談タブより AWS アカウントの承認依頼メールをご送信ください。',
      descriptionDuringClosed3:
        'Online Ask an Expert の利用をご希望の方は、オンライン相談タブよりご予約ください。'
    },
    event: {
      sideBarTitle: 'イベントを\n楽しむ。',
      empty: '現在、開催予定のイベントはありません',
      apply: '詳細・申し込み'
    },
    aae: {
      descriptionTitle: 'Online Ask an Expert',
      sideBarTitle: 'エキスパートに\n相談する。',
      description1: '無償で AWS に関する技術相談が可能です。',
      description2:
        '[相談例]\n' +
        '・新サービスの構成を相談したい。\n' +
        '・セキュリティ設定は現状で充分か？\n' +
        '・もっと最適な AWS の機能はないか？\n',
      description3:
        '実際に相談された方の内容まとめは、<a>AWS Startup ブログ</a> 内、<a>週刊 Ask an Expert</a> で掲載中！' +
        '<a>#AWSLoft</a> を確認してみてくださいね。',
      reserve: 'オンライン相談を予約する',
      approveRequestMailSubject: 'AWS Loft App 承認依頼',
      approveRequestMailBody:
        'AWS Loft App の承認をお願いします。\n' +
        '名前: ${0}\n' +
        'AWS アカウント ID: ${1}\n\n' +
        '---\n' +
        '5 営業日を目途に返信させていただきます。\n' +
        'AWS アカウント ID の有効性が確認されない場合、お客様側で AWS アカウントをアクティブにした上で再度ご連絡ください。',
      unapprovedDescription1:
        'Online Ask an Expert は、お客様がお持ちの AWS アカウント ID の有効性が確認でき次第、ご利用いただけます。' +
        'ご利用希望の方は、下記ボタンより承認依頼のメールをお送りください。',
      unapprovedDescription2:
        'メーラーが立ち上がり、お名前と 12 桁の AWS アカウント ID が本文に表示されますので、そのままご送信ください。' +
        '(本文に表示されない場合は、お手数ですが、ご登録いただいたお名前と AWS アカウント ID をご自身で本文に入力のうえ、ご送信ください。)',
      unapprovedDescription3: '※ 5 営業日以内を目途に返信させていただきます。',
      unapprovedDescription4:
        '※ AWS アカウント作成に関して、よくあるご質問については<a>こちら</a>よりご確認ください。',
      sendApproveRequestMail: '承認依頼メールを送信する',
      unauthenticatedDescription1:
        'ご利用には AWS Loft App のアカウントが必要です。\n' +
        '右上のリンクよりサインイン、もしくは以下のボタンより新規にアカウント登録をお願いいたします。'
    },
    reserve: {
      reservePageTitle: 'オンライン相談',
      choose: '選択',
      release: '選択解除',
      cancel: 'キャンセル',
      edit: '更新',
      confirm: '予約確定',
      here: 'こちら',
      aaeEventName: 'General / AWS に関する全般',
      noEmpty: '空き枠なし',
      meetingRoomNavigate: 'から Meeting Room にアクセスできます。',
      consultationContent: '相談内容をご入力ください。',
      cancelConfirmText: '予約をキャンセルしますか？',
      editSlotConfirmText: '相談内容を更新しますか？',
      registerSlotConfirmText: 'この内容で予約しますか？',
      slotLoadingText: '更新中...',
      editSlotCompleted: '予約情報を更新しました',
      editSlotFailed: '予約情報の更新に失敗しました',
      cancelSlotCompleted: '予約をキャンセルしました',
      cancelSlotFailed: '予約をキャンセルに失敗しました',
      pleaseReload:
        'エラーが発生しました。画面をリロードして再度お試しください。',
      errorMessageForNoInput: '相談内容は必須項目です',
      remain: '残り',
      slot: '枠',
      reservationSlot: '予約枠',
      reservationRemain: '残りの予約可能数 : ',
      reserveDescription1:
        '開始時間の 1 時間前まで予約が可能です。同日に複数予約はできません。',
      reserveDescription2:
        '予約した相談が終了またはキャンセルされると予約可能数が追加されます。',
      noSlotDescription: '空いている予約枠がありません',
      reserved: '予約済み'
    },
    access: {
      loftBusinessHours: 'AWS Startup Loft Tokyo 通常営業時間',
      aaeBusinessHours: 'Ask an Expert 通常営業時間',
      location: '所在地',
      addressDetail:
        '〒 141-0021 東京都品川区上大崎 3-1-1 目黒セントラルスクエア 17F',
      accessDetail:
        'JR 山手線、東京メトロ南北線、都営三田線、東急目黒線「目黒駅」より徒歩 1 分',
      finalEntranceTime: '最終入場時間 : 17:30',
      finalConsultationTime: '最終相談開始時間 : 17:30',
      openHours: 'Open : 10:00',
      closeHours: 'Close : 18:00'
    },
    otherMenu: {
      editProfile: '登録情報の編集',
      access: 'AWS Startup Loft Tokyo へのアクセス',
      changePassword: 'パスワードの変更',
      faq: 'よくある質問',
      eventRule: 'AWS イベント規約',
      loftTerms: 'AWS Startup Loft Tokyo 利用規約',
      privacyNotification: 'Amazon Web Services プライバシー通知',
      communityTerms: 'AWS コミュニティ行動規範',
      questions: 'AWS Startup Loft Tokyo へのご質問・ご相談'
    },
    signUp: {
      mismatch: '確認用のパスワードが一致しません',
      sentCode: '宛に確認コードを送信しました',
      register: '新規登録',
      description: '以下の項目をすべて入力のうえ、会員登録を完了してください',
      privacyCheckDescription:
        'このフォームへの記入を完了することで、Amazon Web Services, Inc. ' +
        'およびその関連会社から、メールおよび郵便により AWS のサービス、' +
        'イベント、キャンペーンに関する情報、および自身が AWS に関して求める' +
        '情報の受信を希望することを表明します。' +
        '配信は、各案内でお知らせする手順に従っていつでも停止できます。' +
        '個人情報の取り扱いは、AWS プライバシー通知に従います。',
      privacyNotification: 'AWS プライバシー通知',
      ruleCheckDescription:
        '私は AWS Startup Loft Tokyo 利用規約および AWS コミュニティ行動規範を確認し、同意しました。',
      loftTerms: 'AWS Startup Loft Tokyo 利用規約',
      communityTerms: 'AWS コミュニティ行動規範',
      credentialCheckDescription:
        '本フォームにご入力頂いた情報はお客様の AWS アカウント情報と紐づけられます。' +
        '本アプリのログイン ID、パスワード及び QR コードを他者と共有する事はできません。' +
        'お客様は、これらの情報が第三者に使用される事がないよう管理する責任を負います。'
    },
    confirmSignUp: {
      inputCode: '確認コードの入力',
      sentCode: '宛に確認コードを送信しました',
      description:
        '確認コードが記載されたメールが届かない場合は、「確認コードを再送する」をクリックしてください。',
      resendCode: '確認コードを再送する',
      confirm: '確認',
      success: 'メールアドレスの認証に成功しました'
    },
    signIn: {
      signIn: 'サインイン',
      password: 'パスワード',
      forgotPassword: 'パスワードを忘れた方',
      register: '登録',
      emptyPassword: 'パスワードを入力してください'
    },
    signOut: {
      signOut: 'サインアウト',
      wannaSignOut: 'サインアウトしますか？'
    },
    profile: {
      edit: '登録情報の編集',
      companyNameSample: '例）アマゾン ウェブ サービス ジャパン株式会社',
      jobPositionSample: '例）部長',
      openConsole: 'AWS コンソールを開く',
      update: '登録情報の更新',
      completed: '登録情報を更新しました'
    },
    changePassword: {
      currentPassword: '現在のパスワード',
      newPassword:
        '新しいパスワード(8 文字以上、大文字、小文字、数字をそれぞれ含む)',
      confirmPassword: 'もう一度新しいパスワードを入力してください',
      change: 'パスワードの変更',
      mismatch: '確認用のパスワードが一致しません',
      completed: '新しいパスワードの設定が完了しました'
    },
    forgotPassword: {
      sendCode: '確認コードを送信',
      sentCode: '確認コードを送信しました',
      newPassword:
        '新しいパスワード(８文字以上、大文字、小文字、数字をそれぞれ含む)',
      confirmPassword: 'もう一度新しいパスワードを入力してください',
      change: 'パスワードの変更',
      mismatch: '確認用のパスワードが一致しません',
      completed: '新しいパスワードの設定が完了しました'
    },
    faq: {
      faq: 'よくある質問'
    },
    // TODO: サーバーサイドのエラーケースを確認して日本語化
    'Username cannot be empty': 'メールアドレス (ログイン ID) は必須入力です',
    'Username/client id combination not found.':
      'メールアドレス (ログイン ID) が見つかりません',
    'An account with the given email already exists.':
      '対象のメールアドレスはすでに存在します',
    'Username should be an email.': 'メールアドレスの形式である必要があります',
    'User does not exist.':
      'メールアドレス (ログイン ID) またはパスワードが異なります',
    "PreSignUp failed with error industry: '' is not a valid IndustryEnum.":
      '業種を選択してください',
    'Invalid verification code provided, please try again.':
      '確認コードが正しくありません。再度お試しください',
    'Incorrect username or password.':
      'メールアドレス (ログイン ID) またはパスワードが異なります',
    'Incorrect password.': 'パスワードが異なります',
    'Attempt limit exceeded, please try after some time.':
      '試行回数の上限を超過しました。しばらく経ってから再度実行してください。'
  }
};
