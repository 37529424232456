// @flow
import React from 'react';
import { Auth, I18n } from 'aws-amplify';
import { withSnackbar } from 'notistack';
import {
  Authenticator,
  ForgotPassword as AmplifyForgotPassword
} from 'aws-amplify-react';
import CompletePassword from '../../components/forgot-password/CompletePassword';
import { withCloseNavigationBar } from '../../components/common/CloseNavigationBar';
import { inject, observer } from 'mobx-react';

// TODO: Function Component 化
// TODO: Amplify の ForgotPassword を継承しない形にする

@inject('userSession')
@observer
class CompletePasswordContainer extends AmplifyForgotPassword {
  constructor(props) {
    super(props);
    if (typeof this.props.location.state === 'undefined') {
      this.props.history.push('/');
      return;
    }
    this.inputs['username'] = this.props.location.state.username;
    this.state = {
      loading: false
    };
    this.customCompletePassword = this.customCompletePassword.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  customCompletePassword: void => void;
  customCompletePassword() {
    const { authData = {}, history, enqueueSnackbar } = this.props;
    const { code, password, confirmationPassword } = this.inputs;
    if (password !== confirmationPassword) {
      enqueueSnackbar(I18n.get('forgotPassword').mismatch, {
        variant: 'error'
      });
      return;
    }
    const username = this.inputs.username || authData.username;
    if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
      throw new Error(
        'No Auth module found, ' + 'please ensure @aws-amplify/auth is imported'
      );
    }
    this.setState({ loading: true });
    Auth.forgotPasswordSubmit(username, code, password)
      .then(data => {
        this.changeState('signIn');
        enqueueSnackbar(I18n.get('forgotPassword').completed, {
          variant: 'success'
        });
        this.setState({ delivery: null });
        history.push('/');
      })
      .catch(err => {
        const error = Object.prototype.hasOwnProperty.call(err, 'message')
          ? err.message
          : err;
        enqueueSnackbar(I18n.get(error), {
          variant: 'error'
        });
        this.setState({ loading: false });
      });
  }

  handleInputChange: (evt: any) => void;
  handleInputChange(evt) {
    this.inputs = this.inputs || {};
    const { name, value, type, checked } = evt.target;
    const checkType = ['radio', 'checkbox'].includes(type);
    this.inputs[name] = checkType ? checked : value;
    this.inputs['checkedValue'] = checkType ? value : null;
    this.setState(this.inputs);
  }

  render() {
    const { history, userSession } = this.props;
    return (
      <Authenticator
        authState="signIn"
        hideDefault={true}
        onStateChange={authState => {
          if (authState === 'signedIn') {
            userSession.syncAuthorizationUser();
            history.push('/');
          }
        }}
      >
        <CompletePassword
          loading={this.state.loading}
          customCompletePassword={this.customCompletePassword}
          handleInputChange={this.handleInputChange}
          userName={this.inputs['username']}
          password={this.inputs['password']}
          confirmationPassword={this.inputs['confirmationPassword']}
          code={this.inputs['code']}
        />
      </Authenticator>
    );
  }
}

export default withCloseNavigationBar(withSnackbar(CompletePasswordContainer), {
  isWhite: false
});
