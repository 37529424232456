// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  avatar: {
    marginTop: '14px',
    textDecoration: 'none'
  }
});

type Props = {
  classes: Object,
  userNameFirstChar: string
};

const ProfileIcon = ({ classes, userNameFirstChar = '' }: Props) => (
  <Avatar
    data-testid="profileIcon"
    className={classes.avatar}
    component={Link}
    to="/profile"
  >
    {userNameFirstChar}
  </Avatar>
);

export default withStyles(styles)(ProfileIcon);
