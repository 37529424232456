// @flow
import React from 'react';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type Props = {
  title: string,
  description: string
};
const Expansion = (_props: Props) => {
  const { title, description, ...props } = _props;
  return (
    <>
      <div {...props}>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h3">{title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography
              variant="body1"
              color="primary"
              dangerouslySetInnerHTML={{ __html: description }}
            ></Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </>
  );
};

export default Expansion;
