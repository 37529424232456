// @flow
import React from 'react';
import Faq from '../../components/faq/Faq';
import { I18n } from 'aws-amplify';
import { faqs } from '../../configs/Faq';
import { withCloseNavigationBar } from '../../components/common/CloseNavigationBar';

export const useFaq = () => {
  // NOTE: 未定義の言語の場合、日本語の質問を返す
  let lang = 'ja';
  if (I18n.get('lang') !== 'lang') {
    lang = I18n.get('lang');
  }
  const _faqs = faqs[lang];
  return { faqs: _faqs };
};

const FaqContainer = () => {
  return <Faq {...useFaq()} />;
};
export default withCloseNavigationBar(FaqContainer);
