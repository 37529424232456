// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Checkbox, FormControlLabel } from '@material-ui/core';

const styles = theme => ({
  consent: {
    marginTop: '10px',
    marginBottom: '10px'
  }
});

type Props = {
  classes: Object,
  name: string,
  checked: boolean,
  label: string,
  handleInputChange: any => void
};

const CheckBox = ({
  classes,
  name = '',
  checked = false,
  label = '',
  handleInputChange = (e: any) => {}
}: Props) => (
  <FormControlLabel
    className={classes.consent}
    control={
      <Checkbox
        name={name}
        checked={checked}
        onChange={handleInputChange}
        color="secondary"
      />
    }
    label={
      <Typography variant="h6" color="primary" gutterBottom inline>
        {label}
      </Typography>
    }
  />
);

export default withStyles(styles)(CheckBox);
