// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  button: {
    marginTop: '16px',
    paddingRight: '16px',
    color: theme.palette.text.hint
  }
});

type Props = {
  classes: Object
};

const SignIn = ({ classes }: Props) => (
  <Button
    data-testid="signInLink"
    component={Link}
    to="/signin"
    className={classes.button}
  >
    {I18n.get('header').signIn}
  </Button>
);

export default withStyles(styles)(SignIn);
