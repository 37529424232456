// @flow
import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Approval from '../../components/top/Approval';
import Error from '../../components/common/Error';
import Loading from '../../components/common/Loading';
import { API, graphqlOperation } from 'aws-amplify';
import UserSession from '../../stores/UserSession';
import gql from 'graphql-tag';

type Props = {
  userSession: UserSession
};

export const useApproval = (userSession: UserSession) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  let subscription;
  const refreshInfo = () => {
    setLoading(true);
    userSession
      .syncAuthorizationUser()
      .then(result => {
        setLoading(false);
      })
      .catch(e => {
        setError(true);
        setLoading(false);
      });
  };
  const subscribeApproval = gql`
    subscription($sub: String!) {
      subscribeApprovedSub(sub: $sub) {
        sub
      }
    }
  `;
  useEffect(() => {
    const graphql = API.graphql(
      graphqlOperation(subscribeApproval, {
        sub: userSession.sub
      })
    );
    subscription = graphql.subscribe({
      next: data => {
        if (data) {
          setLoading(true);
          /*
            NOTE: Loft 受付で認可した際に画面をリフレッシュするが、認可状態は結果整合で書き込まれる
            そのため、すぐに認可状態を読み込むと古いデータが返るため、3 秒の Jitter を入れる
          */
          setTimeout(() => {
            userSession.syncAuthorizationUser().then(result => {
              setLoading(false);
            });
          }, 3000);
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return { loading, error, refreshInfo };
};

const ApprovalContainer = inject('userSession')(
  observer(({ userSession }: Props) => {
    const { loading, error, refreshInfo } = useApproval(userSession);
    if (loading) {
      return <Loading />;
    } else if (error) {
      return <Error />;
    } else {
      return (
        <Approval accountId={userSession.accountId} refreshInfo={refreshInfo} />
      );
    }
  })
);

export default ApprovalContainer;
