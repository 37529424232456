// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import { FormControl, Grid, Typography } from '@material-ui/core';
import { SelectValidator } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textLabel: {
    margin: '20px 4px 10px 4px'
  },
  formControl: {
    width: '100%',
    border: '1px solid #E0E4E8',
    borderRadius: '6.4px'
  },
  selectField: {
    color: '#283C50',
    paddingLeft: '8px',
    height: '56px',
    letterSpacing: '1.2px'
  }
});

type Props = {
  classes: Object,
  name: string,
  value: string,
  options: Object,
  handleInputChange: any => void
};

const ProfileSelect = ({
  classes,
  name = '',
  value = '',
  options = {},
  handleInputChange = (e: any) => {}
}: Props) => (
  <Grid item xs={12} justify="flex-start" container>
    <Typography
      variant="h5"
      color="primary"
      gutterBottom
      className={classes.textLabel}
    >
      {I18n.get('common')[name]}
    </Typography>
    <FormControl variant="filled" className={classes.formControl}>
      <SelectValidator
        name={name}
        value={value}
        onChange={handleInputChange}
        validators={['required', 'minStringLength:1']}
        errorMessages={['必須項目です', '必須項目です']}
        SelectProps={{
          native: true,
          classes: {
            select: classes.selectField
          }
        }}
      >
        <option key="" value="">
          {I18n.get('common').choose}
        </option>
        {Object.keys(options).map(function(key) {
          return (
            <option key={this[key]} value={this[key]}>
              {key}
            </option>
          );
        }, options)}
      </SelectValidator>
    </FormControl>
  </Grid>
);

export default withStyles(styles)(ProfileSelect);
