// @flow
import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  selectButton: {
    margin: theme.spacing.unit,
    height: '48px'
  },
  selectButtonTextDisabled: {
    color: 'rgba(0, 0, 0, 0.26)'
  },
  selectButtonBorderDisabled: {
    border: '1px solid #E0E4E8',
    borderRadius: '6.4px'
  }
});

type Props = {
  classes: Object,
  name: string,
  on: boolean,
  value: string,
  displayValue: string,
  handleInputChange: any => void
};

const ProfileButton = ({
  classes,
  name = '',
  on = false,
  value = '',
  displayValue = '',
  handleInputChange = (e: any) => {}
}: Props) => (
  <Button
    variant="outlined"
    data-testid={`${name}${value}`}
    onClick={handleInputChange}
    value={value}
    name={name}
    className={classes.selectButton}
    color="secondary"
    classes={
      on === true
        ? null
        : {
            label: classes.selectButtonTextDisabled,
            root: classes.selectButtonBorderDisabled
          }
    }
  >
    {displayValue}
  </Button>
);

export default withStyles(styles)(ProfileButton);
