// @flow
import React from 'react';
import { Link } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { I18n } from 'aws-amplify';
import { Tab, Tabs, withStyles, Paper, Grid } from '@material-ui/core';
import SignIn from './SignIn';
import ProfileIcon from './ProfileIcon';
import {
  // LoftEventIcon,
  LoftHomeIcon,
  LoftOthersIcon
} from '../common/LoftSvgIcon';
import { TAB_INDEX } from '../../const/';
import awsloft from '../../assets/img/LoftIconTokyo.png';
import officeHourIcon from '../../assets/img/OfficeHourIcon.png';
import officeHourIconSelected from '../../assets/img/OfficeHourIconSelected.png';

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.primary,
    position: 'fixed',
    zIndex: 100,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.48)'
  },
  padding: {
    paddingTop: '120px'
  },
  headerContainer: {
    margin: '0 auto',
    maxWidth: '1024px'
  },
  header: {
    height: '62px'
  },
  title: {
    paddingTop: '14px',
    paddingLeft: '16px'
  },
  tabsRoot: {
    height: '68px',
    color: '#64788C',
    selectedTextColor: '#FF0000'
  },
  tabRoot: {
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '10px',
    letterSpacing: '0.4px'
  }
});

type Props = {
  classes: Object,
  selectTab: number => void,
  selectedTabId: number,
  isAuthenticated: boolean,
  userNameFirstChar: string,
  isLoading: boolean
};

const Header = ({
  classes,
  selectTab = tabId => {},
  selectedTabId = 0,
  isAuthenticated = false,
  userNameFirstChar = '',
  isLoading = false
}: Props) => (
  <div>
    <Helmet>
      <style type="text/css">
        {`
        body {
          background-color: #232F3E;
          color: #FFF;
        }
      `}
      </style>
    </Helmet>
    <Paper square className={classes.root}>
      <Grid container spacing={0} className={classes.headerContainer}>
        <Grid
          item
          xs={6}
          spacing={8}
          alignItems="center"
          justify="flex-start"
          container
          className={classes.header}
        >
          <Grid item>
            <Link
              href="https://aws.amazon.com/jp/startups/lofts/tokyo-loft/"
              target="_blank"
            >
              <img
                className={classes.title}
                src={awsloft}
                height="40"
                width="166"
                alt="AWS Startup Loft Tokyo"
              />
            </Link>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          spacing={8}
          alignItems="center"
          justify="flex-end"
          container
          className={classes.header}
        >
          <Grid item>
            {isAuthenticated ? (
              <ProfileIcon userNameFirstChar={userNameFirstChar} />
            ) : (
              <SignIn />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            className={classes.tabsRoot}
            value={selectedTabId}
            onChange={(_, value) => {
              selectTab(value);
            }}
            variant="fullWidth"
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              value={TAB_INDEX.HOME}
              className="loftTab"
              classes={{ root: classes.tabRoot }}
              icon={<LoftHomeIcon />}
              label={I18n.get('header').top}
              disabled={isLoading}
            />
            <Tab
              value={TAB_INDEX.AAE}
              className="loftTab"
              classes={{ root: classes.tabRoot }}
              icon={
                selectedTabId === TAB_INDEX.AAE ? (
                  <img src={officeHourIconSelected} height="28" width="28" />
                ) : (
                  <img src={officeHourIcon} height="28" width="28" />
                )
              }
              label={I18n.get('header').aae}
              disabled={isLoading}
            />
            <Tab
              value={TAB_INDEX.MENU}
              className="loftTab"
              classes={{ root: classes.tabRoot }}
              icon={<LoftOthersIcon viewBox="-2 0 24 24" />}
              label={I18n.get('header').others}
              disabled={isLoading}
            />
          </Tabs>
        </Grid>
      </Grid>
    </Paper>
    <div className={classes.padding}></div>
  </div>
);

export default withStyles(styles)(Header);
