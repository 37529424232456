// @flow
import React, { Component } from 'react';
import { Auth, API, I18n } from 'aws-amplify';
import { Authenticator } from 'aws-amplify-react';
import { withSnackbar } from 'notistack';
import { inject, observer } from 'mobx-react';
import SignUp from '../../components/signup/SignUp';
import Error from '../../components/common/Error';
import { withCloseNavigationBar } from '../../components/common/CloseNavigationBar';
import { type RouterHistory } from 'react-router-dom';

// TODO: Function Component へ変更する

type Props = {
  history: RouterHistory,
  enqueueSnackbar: any,
  signUpFormValue: any
};

type State = {
  loading: boolean,
  error: boolean,
  buttonDisabled: boolean,
  options: Object
};

@inject('signUpFormValue')
@observer
class SignUpContainer extends Component<Props, State> {
  _isMounted: boolean;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      buttonDisabled: true,
      options: {}
    };
    this.customSignUp = this.customSignUp.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this._isMounted = true;
  }

  componentDidMount: () => void;
  componentDidMount() {
    API.get('no_auth_user', '/api/v1/__enum?locale=ja', {})
      .then(res => {
        this._isMounted &&
          this.setState({
            options: res
          });
      })
      .catch(e => {
        this._isMounted &&
          this.setState({
            error: true
          });
      });
  }

  componentWillUnmount: () => void;
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange: (e: any) => void;
  handleInputChange(e) {
    const { signUpFormValue } = this.props;
    let { name, value } = e.currentTarget;
    if (e.currentTarget.type === 'checkbox') {
      value = e.currentTarget.checked;
    }
    // NOTE: set + キャメルケースでプロパティに応じたメソッド名を動的に作成
    const f = `set${name.charAt(0).toUpperCase()}${name.slice(1)}`;
    signUpFormValue[f](value);
    const { privacyCheck, ruleCheck, credentialCheck } = signUpFormValue;
    if (privacyCheck && ruleCheck && credentialCheck) {
      this.setState({ buttonDisabled: false });
    } else {
      this.setState({ buttonDisabled: true });
    }
  }

  customSignUp: (e: any) => void;
  customSignUp(e) {
    const { history, enqueueSnackbar, signUpFormValue } = this.props;
    if (signUpFormValue.developerState === '') {
      enqueueSnackbar(I18n.get('validation').chooseDeveloperState, {
        variant: 'error'
      });
      return;
    }
    if (signUpFormValue.password !== signUpFormValue.confirmPassword) {
      enqueueSnackbar(I18n.get('signUp').mismatch, {
        variant: 'error'
      });
      return;
    }
    this.setState({ loading: true });
    Auth.signUp({
      username: signUpFormValue.email,
      password: signUpFormValue.password,
      attributes: {
        family_name: signUpFormValue.familyName,
        given_name: signUpFormValue.givenName,
        'custom:phone': signUpFormValue.phone,
        'custom:zipcode': signUpFormValue.zipcode,
        'custom:country': signUpFormValue.country,
        'custom:company_name': signUpFormValue.companyName,
        'custom:company_scale': signUpFormValue.companyScale,
        'custom:startup_relationship': signUpFormValue.startupRelationship,
        'custom:job_role': signUpFormValue.jobRole,
        'custom:job_position': signUpFormValue.jobPosition,
        'custom:industry': signUpFormValue.industry,
        'custom:account_id': signUpFormValue.accountId,
        'custom:consumption': signUpFormValue.consumption,
        'custom:developer_state': signUpFormValue.developerState
      }
    })
      .then(() => {
        this.setState({ loading: false });
        enqueueSnackbar(signUpFormValue.email + I18n.get('signUp').sentCode, {
          variant: 'success'
        });
        history.push('/confirm_signup');
      })
      .catch(err => {
        this.setState({ loading: false });
        // NOTE: Lambda で独自実装しているバリデーションの場合、message プロパティにエラーが出る
        const error = Object.prototype.hasOwnProperty.call(err, 'message')
          ? err.message
          : err;
        enqueueSnackbar(I18n.get(error), {
          variant: 'error'
        });
      });
  }

  render() {
    const { signUpFormValue, history } = this.props;
    return (
      <Authenticator
        authState="signUp"
        hideDefault={true}
        onStateChange={authState => {
          if (authState === 'signedIn') {
            history.push('/');
          } else if (authState === 'signedUp') {
            history.push('/confirm_signup');
          }
        }}
      >
        {this.state.error ? (
          <Error />
        ) : (
          <SignUp
            loading={this.state.loading}
            options={this.state.options}
            buttonDisabled={this.state.buttonDisabled}
            profile={signUpFormValue}
            customSignUp={this.customSignUp}
            handleInputChange={this.handleInputChange}
          />
        )}
      </Authenticator>
    );
  }
}

export default withCloseNavigationBar(withSnackbar(SignUpContainer));
