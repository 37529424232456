// @flow
import React, { Component } from 'react';
import { API, I18n } from 'aws-amplify';
import { Authenticator } from 'aws-amplify-react';
import { withSnackbar } from 'notistack';
import { inject, observer } from 'mobx-react';
import Profile from '../../components/profile/Profile';
import Error from '../../components/common/Error';
import { withCloseNavigationBar } from '../../components/common/CloseNavigationBar';
import { type RouterHistory } from 'react-router-dom';
import UserSession from '../../stores/UserSession';

// TODO: Function Component へ変更する

type Props = {
  history: RouterHistory,
  enqueueSnackbar: any,
  userSession: UserSession
};

type State = {
  loading: boolean,
  error: boolean,
  profile: Object,
  options: Object
};

@inject('userSession')
@observer
class ProfileContainer extends Component<Props, State> {
  _isMounted: boolean;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      profile: {},
      options: {}
    };
    this.updateProfile = this.updateProfile.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this._isMounted = true;
  }

  componentDidMount() {
    const { userSession } = this.props;
    API.get('user', '/api/v1/__enum?locale=ja', {})
      .then(res => {
        this._isMounted &&
          this.setState({
            options: res
          });
      })
      .catch(e => {
        this._isMounted &&
          this.setState({
            error: true
          });
      });
    // NOTE: 別タブでデータ更新した場合など、念のためユーザー情報を最新化している
    userSession.syncAuthorizationUser().then(result => {
      this._isMounted &&
        this.setState({
          loading: false,
          profile: { ...userSession }
        });
    });
  }

  componentWillUnmount: () => void;
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange: (e: any) => void;
  handleInputChange(e) {
    const { name, value } = e.currentTarget;
    const profile = this.state.profile;
    this.setState({ profile: { ...profile, [name]: value } });
  }

  updateProfile: () => void;
  updateProfile() {
    const { enqueueSnackbar, userSession, history } = this.props;
    const profile = this.state.profile;
    if (profile.developerState === '') {
      enqueueSnackbar(I18n.get('validation').chooseDeveloperState, {
        variant: 'error'
      });
      return;
    }
    this.setState({ loading: true });
    API.put('user', `/api/v1/user/${profile.sub}`, {
      body: {
        givenName: profile.givenName,
        familyName: profile.familyName,
        startupRelationship: profile.startupRelationship,
        companyName: profile.companyName,
        accountId: profile.accountId,
        zipcode: profile.zipcode.replace('-', ''),
        jobRole: profile.jobRole,
        country: profile.country,
        companyScale: profile.companyScale,
        industry: profile.industry,
        consumption: profile.consumption,
        jobPosition: profile.jobPosition,
        phone: profile.phone,
        developerState: profile.developerState
      }
    })
      .then(() => {
        userSession.syncAuthorizationUser();
        enqueueSnackbar(I18n.get('profile').completed, {
          variant: 'success'
        });
        history.push('/');
      })
      .catch(err => {
        const error = Object.prototype.hasOwnProperty.call(err, 'message')
          ? err.message
          : err;
        enqueueSnackbar(I18n.get(error), {
          variant: 'error'
        });
        this.setState({ loading: false });
      });
  }

  render() {
    const { history } = this.props;
    return (
      <Authenticator
        authState="signIn"
        hideDefault={true}
        onStateChange={authState => {
          if (authState !== 'signedIn') {
            history.push('/');
          }
        }}
      >
        {this.state.error ? (
          <Error />
        ) : (
          <Profile
            loading={this.state.loading}
            options={this.state.options}
            profile={this.state.profile}
            updateProfile={this.updateProfile}
            handleInputChange={this.handleInputChange}
          />
        )}
      </Authenticator>
    );
  }
}

export default withCloseNavigationBar(withSnackbar(ProfileContainer));
