import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Amplify from 'aws-amplify';
import { I18n } from 'aws-amplify';
import { dict } from './configs/I18n';
import awsExports from './aws-exports';
import * as serviceWorker from './serviceWorker';

Amplify.configure(awsExports);
I18n.putVocabularies(dict);
I18n.setLanguage(process.env.REACT_APP_LANG);
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
