// @flow
import React from 'react';
import { I18n } from 'aws-amplify';

import { Typography, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm } from 'react-material-ui-form-validator';

import PasswordInput from '../common/PasswordInput';
import loftJapan from '../../assets/img/LoftJapan.png';
import awsloft from '../../assets/img/LoftIconTokyo.png';

const styles = theme => ({
  root: {
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundColor: theme.palette.background.secondary
  },
  button: {
    borderRadius: '28px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '15px',
    color: '#FFFFFF',
    letterSpacing: '2.4px',
    width: '311px',
    height: '56px',
    marginTop: '20px'
  },
  textFieldContainer: {
    marginLeft: '20px',
    paddingRight: '20px'
  },
  validatorForm: {
    width: '100%',
    marginBottom: '60px'
  },
  topImage: {
    maxWidth: 'min(420px, 100vw)',
    maxHeight: '142px'
  },
  buttonProgress: {
    color: '#ffffff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: -12
  },
  wrapper: {
    position: 'relative'
  }
});

type Props = {
  classes: Object,
  formRef: any,
  passwords: {
    current: string,
    new: string,
    confirm: string
  },
  loading: boolean,
  isFormValid: boolean,
  customChangePassword: void => void,
  handleInputChange: void => void,
  validatorListener: void => void
};

const ChangePassword = ({
  classes,
  formRef = null,
  passwords = { current: '', new: '', confirm: '' },
  loading = false,
  isFormValid = false,
  customChangePassword = () => {},
  handleInputChange = () => {},
  validatorListener = () => {}
}: Props) => (
  <div className={classes.root}>
    <Grid container spacing={24} justify="center">
      <Grid container item justify="center">
        <img
          src={loftJapan}
          alt="AWS Startup Loft Tokyo"
          className={classes.topImage}
        />
      </Grid>
      <Grid container item justify="center">
        <img
          src={awsloft}
          height="63"
          width="262"
          alt="AWS Startup Loft Tokyo"
        />
      </Grid>
      <Grid item>
        <ValidatorForm
          ref={formRef}
          onSubmit={customChangePassword}
          className={classes.validatorForm}
        >
          <Grid container spacing={24} justify="center">
            <Grid item xs={12}>
              <div className={classes.textFieldContainer}>
                <Typography variant="h5" align="left">
                  {I18n.get('changePassword').currentPassword}
                </Typography>
                <PasswordInput
                  name={'current'}
                  handleInputChange={handleInputChange}
                  value={passwords.current}
                  label={I18n.get('common').alphaNumericSymbol}
                  validatorListener={validatorListener}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.textFieldContainer}>
                <Typography variant="h5" align="left">
                  {I18n.get('changePassword').newPassword}
                </Typography>
                <PasswordInput
                  name={'new'}
                  handleInputChange={handleInputChange}
                  value={passwords.new}
                  label={I18n.get('common').alphaNumericSymbol}
                  validatorListener={validatorListener}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.textFieldContainer}>
                <Typography variant="h5" align="left">
                  {I18n.get('changePassword').confirmPassword}
                </Typography>
                <PasswordInput
                  name={'confirm'}
                  handleInputChange={handleInputChange}
                  value={passwords.confirm}
                  label={I18n.get('common').alphaNumericSymbol}
                  validatorListener={validatorListener}
                />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  className={classes.button}
                  disabled={loading || !isFormValid}
                >
                  {I18n.get('changePassword').change}
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Grid>
    </Grid>
  </div>
);
export default withStyles(styles)(ChangePassword);
