// @flow
import React, { useState, useContext } from 'react';
import { I18n } from 'aws-amplify';
import { type RouterHistory } from 'react-router-dom';
import type { Appointment } from '../../type/aae';
import { AAE_DURATION } from '../../const/index';
import UserSession from '../../stores/UserSession';
import { AaeApi } from '../../api/aae';
import AppointmentComponent from '../../components/aae/Appointment';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import DateUtil from '../../utils/Date';
import { DispatchContext } from './context';

export const useAppointment = (
  history: RouterHistory,
  appointment: Appointment,
  userSession: UserSession
) => {
  const [detail, setDetail] = useState(appointment.detail);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useContext(DispatchContext);
  const { enqueueSnackbar } = require('notistack').useSnackbar();

  const handleInputChange = (e: any) => {
    setDetail(e.target.value);
  };

  const handleError = (e: any) => {
    enqueueSnackbar(I18n.get('reserve').editSlotFailed, {
      variant: 'error'
    });
    setErrorMessage(I18n.get('reserve').pleaseReload);
    setIsDialogLoading(false);
    return;
  };

  const editAppointment = async () => {
    setIsDialogLoading(true);
    try {
      await AaeApi.callEditSlotApi(
        userSession.sub,
        appointment.time,
        detail,
        appointment.type,
        appointment.uuid
      );
    } catch (e) {
      handleError(e);
      return;
    }
    enqueueSnackbar(I18n.get('reserve').editSlotCompleted, {
      variant: 'success'
    });
    setIsEditDialogOpen(false);
    setIsDialogLoading(false);
    dispatch({
      type: 'SET_LOADING_STATE',
      isLoading: true
    });
  };

  const cancelAppointment = async () => {
    setIsDialogLoading(true);
    try {
      await AaeApi.callCancelSlotApi(userSession.sub, appointment.uuid);
    } catch (e) {
      handleError(e);
      return;
    }
    enqueueSnackbar(I18n.get('reserve').cancelSlotCompleted, {
      variant: 'success'
    });
    setIsCancelDialogOpen(false);
    setIsDialogLoading(false);
    dispatch({
      type: 'SET_LOADING_STATE',
      isLoading: true
    });
  };

  const displayTime = `${DateUtil.getHHMM(
    appointment.time
  )} - ${DateUtil.getHHMM(appointment.time + AAE_DURATION)}`;
  const disabled = appointment.time < DateUtil.getCurrentUnixTime();
  return {
    disabled,
    time: displayTime,
    type: appointment.type,
    eventName: appointment.eventName
      ? appointment.eventName
      : I18n.get('reserve').aaeEventName,
    description: appointment.description ? appointment.description : '',
    detail,
    handleInputChange,
    isDialogLoading,
    isEditDialogOpen,
    setIsEditDialogOpen,
    isCancelDialogOpen,
    setIsCancelDialogOpen,
    editAppointment,
    cancelAppointment,
    errorMessage
  };
};

type Props = {
  history: RouterHistory,
  appointment: Appointment,
  userSession: UserSession
};

const AppointmentContainer = ({ history, appointment, userSession }: Props) => {
  const {
    disabled = false,
    time = '',
    type = 'aae',
    eventName = '',
    description = '',
    detail = '',
    handleInputChange = () => {},
    isDialogLoading = false,
    isEditDialogOpen = false,
    setIsEditDialogOpen = bool => {},
    isCancelDialogOpen = false,
    setIsCancelDialogOpen = bool => {},
    editAppointment = () => {},
    cancelAppointment = () => {},
    errorMessage = ''
  } = useAppointment(history, appointment, userSession);
  return (
    <>
      <AppointmentComponent
        disabled={disabled}
        time={time}
        type={type}
        eventName={eventName}
        description={description}
        chimeId={appointment.chimeId}
        detail={detail}
        handleInputChange={handleInputChange}
        setIsEditDialogOpen={setIsEditDialogOpen}
        setIsCancelDialogOpen={setIsCancelDialogOpen}
      />
      {/* 予約内容更新用ダイアログ */}
      <ConfirmDialog
        isOpen={isEditDialogOpen}
        isLoading={isDialogLoading}
        errorMessageText={errorMessage}
        loadingText={I18n.get('reserve').slotLoadingText}
        confirmText={I18n.get('reserve').editSlotConfirmText}
        execute={() => editAppointment()}
        doCancel={() => setIsEditDialogOpen(false)}
      />
      {/* キャンセル用ダイアログ */}
      <ConfirmDialog
        isOpen={isCancelDialogOpen}
        isLoading={isDialogLoading}
        errorMessageText={errorMessage}
        confirmText={I18n.get('reserve').cancelConfirmText}
        loadingText={I18n.get('reserve').slotLoadingText}
        execute={() => cancelAppointment()}
        doCancel={() => setIsCancelDialogOpen(false)}
      />
    </>
  );
};

export default AppointmentContainer;
