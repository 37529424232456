// @flow
import React from 'react';
import { Grid, Hidden, Typography, withStyles } from '@material-ui/core';
import { I18n } from 'aws-amplify';
import { formatReturnToHtml } from '../../utils/FormatString';
import EventCard from './EventCard';
import sideBar from '../../assets/img/SideBar.jpg';
import nanako from '../../assets/img/Nanako.png';

const styles = theme => ({
  root: {
    maxWidth: '100%',
    paddingTop: '5px',
    marginBottom: '30px',
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundColor: theme.palette.background.secondary
  },
  sideBar: {
    position: 'relative',
    textAlign: 'center',
    backgroundColor: 'rgba(22, 30, 45, 0.8)',
    height: '902px',
    marginTop: '-2px'
  },
  sideBarTitle: {
    fontSize: 'min(4vw, 44px)',
    position: 'absolute',
    color: '#FFFFFF',
    top: '240px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    whiteSpace: 'nowrap',
    lineHeight: 1.5
  },
  sideBarImage: {
    height: '902px',
    width: '100%',
    opacity: 0.2,
    marginBottom: 0
  },
  sideBarCharacter: {
    position: 'absolute',
    top: '480px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45%',
    minWidth: '110px'
  },
  events: {
    paddingTop: '20px',
    paddingBottom: '20px'
  }
});

type EventProps = {
  date: string,
  title: string,
  types: string[],
  link: string,
  state: string
};
type Props = {
  classes: Object,
  events: EventProps[]
};

const Event = ({ classes, events }: Props) => (
  <div className={classes.root}>
    <Grid container justify="flex-start" direction="row">
      <Hidden xsDown>
        <Grid item sm={4} className={classes.sideBar}>
          <img src={sideBar} className={classes.sideBarImage} />
          <Typography variant="h1" className={classes.sideBarTitle}>
            {formatReturnToHtml(I18n.get('event').sideBarTitle)}
          </Typography>
          <img src={nanako} className={classes.sideBarCharacter}></img>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={8}>
        <Grid
          container
          spacing={0}
          justify="flex-start"
          direction="row"
          className={classes.events}
        >
          {events.map((event, index) => (
            <EventCard key={index} event={event} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(Event);
