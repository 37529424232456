// @flow
import React, { useState, useRef } from 'react';
import { I18n, Auth } from 'aws-amplify';
import ChangePassword from '../../components/change-password/ChangePassword';
import { withCloseNavigationBar } from '../../components/common/CloseNavigationBar';
import { type RouterHistory } from 'react-router-dom';
import { Authenticator } from 'aws-amplify-react';

type Props = {
  history: RouterHistory
};

export const useChangePassword = (history: RouterHistory) => {
  const [passwords, setPasswords] = useState({
    current: '',
    new: '',
    confirm: ''
  });
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { enqueueSnackbar } = require('notistack').useSnackbar();
  const formRef = useRef<Object | null>(null);

  const handleInputChange = (e: any) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const validatorListener = async () => {
    if (formRef.current !== null) {
      setIsFormValid(await formRef.current.isFormValid());
    } else {
      setIsFormValid(false);
    }
  };

  const customChangePassword = () => {
    if (passwords.new !== passwords.confirm) {
      enqueueSnackbar(I18n.get('changePassword').mismatch, {
        variant: 'error'
      });
      return;
    }
    setLoading(true);
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, passwords.current, passwords.new);
      })
      .then(data => {
        enqueueSnackbar(I18n.get('changePassword').completed, {
          variant: 'success'
        });
        history.push('/');
      })
      .catch(err => {
        let error = Object.prototype.hasOwnProperty.call(err, 'message')
          ? err.message
          : err;
        console.log(error);
        if (error === 'Incorrect username or password.') {
          /*
            NOTE: 現在のパスワードに誤りがある場合、エラーメッセージが Incorrect username or password で
            サーバーから返ってしまう。ユーザー名はここでは使われず、メッセージとして正しくない。
            そのため、暫定対処としてメッセージを入れ替える。
          */
          error = 'Incorrect password.';
        }
        enqueueSnackbar(I18n.get(error), {
          variant: 'error'
        });
        setLoading(false);
      });
  };
  return {
    passwords,
    formRef,
    loading,
    isFormValid,
    handleInputChange,
    customChangePassword,
    validatorListener
  };
};

const ChangePasswordContainer = ({ history }: Props) => {
  return (
    <Authenticator
      authState="signIn"
      hideDefault={true}
      onStateChange={authState => {
        if (authState !== 'signedIn') {
          history.push('/');
        }
      }}
    >
      <ChangePassword {...useChangePassword(history)} />
    </Authenticator>
  );
};

export default withCloseNavigationBar(ChangePasswordContainer, {
  isWhite: false
});
