// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import { Card, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import awsloft from '../../assets/img/LoftIconTokyo.png';
import loftJapan from '../../assets/img/LoftJapan.png';

const styles = theme => ({
  root: {
    marginTop: '20px',
    backgroundColor: theme.palette.background.secondary,
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  card: {
    minWidth: '326px',
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center'
  },
  button: {
    borderRadius: '28px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '15px',
    color: '#FFFFFF',
    letterSpacing: '2.4px',
    width: '311px',
    height: '56px'
  },
  accountIDContent: {
    textAlign: 'center',
    borderLeft: '4px solid #E0E4E8',
    borderRight: '4px solid #E0E4E8',
    marginTop: '4px',
    marginLeft: '4px',
    marginRight: '4px',
    marginBottom: '4px',
    height: '130px'
  },
  yourAccountID: {
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: '44px',
    marginRight: '44px',
    paddingTop: '4px',
    height: '28px',
    width: '200px',
    background: '#F0F2F4',
    borderRadius: '14px',
    textAlign: 'center'
  },
  accountIDText: {
    fontFamily: '.SFNSDisplay',
    fontSize: '32px',
    color: '#283C50',
    letterSpacing: '2.95px',
    textAlign: 'center'
  },
  topImage: {
    maxWidth: 'min(420px, 100vw)',
    maxHeight: '142px'
  },
  topImage2: {
    maxWidth: '100%'
  },
  closedDescription: {
    fontWeight: 'bold',
    marginBottom: '10px'
  },
  messageBox: {
    backgroundColor: '#141E30',
    padding: '10px 10px',
    borderRadius: '10px'
  }
});

type Props = {
  classes: Object,
  accountId: string,
  refreshInfo: () => void
};

const Approval = ({ classes, accountId, refreshInfo = () => {} }: Props) => (
  <div className={classes.root}>
    <Grid container spacing={24} justify="center">
      <Grid container item justify="center">
        <img
          src={loftJapan}
          alt="AWS Startup Loft Tokyo"
          className={classes.topImage}
        />
      </Grid>
      <Grid item>
        <Card className={classes.card}>
          <CardContent className={classes.accountIDContent}>
            <Typography
              variant="h4"
              align="center"
              className={classes.yourAccountID}
            >
              {I18n.get('common').accountId}
            </Typography>
            <Typography className={classes.accountIDText}>
              {accountId}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid container item justify="center">
        <img
          src={awsloft}
          height="63"
          width="262"
          alt="AWS Startup Loft Tokyo"
        />
      </Grid>
      <Grid item>
        <Grid container justify="center">
          <Grid item xs={10} sm={12} className={classes.messageBox}>
            <Typography
              variant="h2"
              align="center"
              color="secondary"
              gutterBottom
              className={classes.closedDescription}
            >
              {I18n.get('home').currentlyClosed}
            </Typography>
            <Typography variant="h6" gutterBottom align="center">
              {I18n.get('home').descriptionDuringClosed1}
            </Typography>
            <Typography variant="h6" gutterBottom align="center">
              {I18n.get('home').descriptionDuringClosed2}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
);
export default withStyles(styles)(Approval);
