// @flow
import React from 'react';
import { inject, observer } from 'mobx-react';
import Header from '../header/Header';
import Top from '../top/Top';
import Event from '../event/Event';
import Menu from '../other-menu/Menu';
import AskAnExpert from '../aae/AskAnExpert';
import TopViewTab from '../../stores/TopViewTab';
import UserSession from '../../stores/UserSession';
import { type RouterHistory } from 'react-router-dom';
import { TAB_INDEX } from '../../const/';

type Props = {
  topViewTab: TopViewTab,
  userSession: UserSession,
  history: RouterHistory
};

const RootContainer = inject('topViewTab', 'userSession')(
  observer(({ topViewTab, userSession, history }: Props) => {
    const selectedTabId = topViewTab.selected;
    return (
      <>
        <Header />
        <div style={{ maxWidth: '1024px', margin: 'auto' }}>
          {selectedTabId === TAB_INDEX.HOME && <Top />}
          {selectedTabId === TAB_INDEX.EVENT && <Event />}
          {selectedTabId === TAB_INDEX.AAE && (
            <AskAnExpert history={history} userSession={userSession} />
          )}
          {selectedTabId === TAB_INDEX.MENU && <Menu />}
        </div>
      </>
    );
  })
);

export default RootContainer;
