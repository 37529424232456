// @flow
import React, { useState, useContext } from 'react';
import { I18n } from 'aws-amplify';
import { type RouterHistory } from 'react-router-dom';
import type { Slot, SlotStatus } from '../../type/aae';
import { AAE_DURATION } from '../../const/index';
import UserSession from '../../stores/UserSession';
import { AaeApi } from '../../api/aae';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import ReservationSlot from '../../components/aae/ReservationSlot';
import DateUtil from '../../utils/Date';
import { DispatchContext } from './context';

const useReservationSlot = (
  history: RouterHistory,
  slot: Slot,
  userSession: UserSession
) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [detail, setDetail] = useState('');
  const dispatch = useContext(DispatchContext);
  const { enqueueSnackbar } = require('notistack').useSnackbar();

  const handleInputChange = (e: any) => {
    setDetail(e.target.value);
  };

  const handleError = (e: any) => {
    enqueueSnackbar(I18n.get('reserve').editSlotFailed, {
      variant: 'error'
    });
    setErrorMessage(I18n.get('reserve').pleaseReload);
    setIsDialogLoading(false);
    return;
  };

  const registerSlot = async () => {
    setIsDialogLoading(true);
    try {
      await AaeApi.callRegisterSlotApi(
        userSession.sub,
        slot.time,
        detail,
        slot.type,
        slot.typeVariant
      );
    } catch (e) {
      handleError(e);
      return;
    }
    enqueueSnackbar(I18n.get('reserve').editSlotCompleted, {
      variant: 'success'
    });
    setIsDialogOpen(false);
    setIsDialogLoading(false);
    dispatch({
      type: 'SET_LOADING_STATE',
      isLoading: true
    });
  };

  const displayTime = `${DateUtil.getHHMM(slot.time)} - ${DateUtil.getHHMM(
    slot.time + AAE_DURATION
  )}`;
  return {
    time: displayTime,
    type: slot.type,
    eventName: slot.eventName
      ? slot.eventName
      : I18n.get('reserve').aaeEventName,
    description: slot.description ? slot.description : '',
    remain: slot.remain,
    detail,
    handleInputChange,
    isDialogLoading,
    isDialogOpen,
    setIsDialogOpen,
    registerSlot,
    errorMessage
  };
};

type Props = {
  history: RouterHistory,
  slot: Slot,
  userSession: UserSession,
  isInputVisible: boolean,
  status: SlotStatus,
  setDetailInputId: string => void
};

const ReservationSlotContainer = ({
  history,
  slot,
  userSession,
  isInputVisible,
  status,
  setDetailInputId
}: Props) => {
  const {
    time,
    type,
    eventName,
    description,
    remain,
    detail,
    handleInputChange,
    isDialogLoading,
    isDialogOpen,
    setIsDialogOpen,
    registerSlot,
    errorMessage
  } = useReservationSlot(history, slot, userSession);
  return (
    <>
      <ReservationSlot
        status={status}
        time={time}
        type={type}
        eventName={eventName}
        description={description}
        remain={remain}
        detail={detail}
        isInputVisible={isInputVisible}
        setDetailInputId={setDetailInputId}
        setIsDialogOpen={setIsDialogOpen}
        handleInputChange={handleInputChange}
      />
      {/* 予約登録ダイアログ */}
      <ConfirmDialog
        isOpen={isDialogOpen}
        isLoading={isDialogLoading}
        errorMessageText={errorMessage}
        loadingText={I18n.get('reserve').slotLoadingText}
        confirmText={I18n.get('reserve').registerSlotConfirmText}
        execute={() => registerSlot()}
        doCancel={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default ReservationSlotContainer;
