// @flow
import { action, observable } from 'mobx';
import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify';
import { ApiError } from '../utils/ApiError';

type UserSessionApiResponse = {
  sub: string,
  email: string,
  givenName: string,
  familyName: string,
  startupRelationship: string,
  companyName: string,
  accountId: string,
  zipcode: string,
  jobRole: string,
  country: string,
  companyScale: string,
  industry: string,
  jobPosition: string,
  phone: string,
  developerState: string,
  isApprovedUser: boolean,
  checkInSignature: string,
  consumption: string
};

export type UserSessionType = UserSessionApiResponse & {
  syncAuthorizationUser: () => Promise<boolean>
};

/* eslint no-invalid-this: 0 */
export default class UserSession {
  @observable sub: string = '';
  @observable email: string = '';
  @observable givenName: string = '';
  @observable familyName: string = '';
  @observable startupRelationship: string = '';
  @observable companyName: string = '';
  @observable accountId: string = '';
  @observable zipcode: string = '';
  @observable jobRole: string = '';
  @observable country: string = '';
  @observable companyScale: string = '';
  @observable industry: string = '';
  @observable consumption: string = '';
  @observable jobPosition: string = '';
  @observable phone: string = '';
  @observable developerState: string = '';
  @observable isApprovedUser: boolean = false;
  @observable checkInSignature: string = '';
  /**
   * ユーザのログイン状態を示す真偽値
   * セッションがexpireしてもこのプロパティは更新されないので注意。厳密にユーザのログイン状態を取得したい場合はsyncAuthorizationUserメソッドを使用すること
   * @type {boolean}
   */
  @observable isAuthenticated = false;

  /**
   * ユーザのログイン状態をPromiseで返却するメソッド
   * syncUserAttribute=trueの場合、ログイン状態のチェックに加えユーザのattributeをサーバから最新化する
   *
   * @param {boolean} [syncUserAttribute=true] ユーザのattributeを最新化するかどうか
   * @type {Promise<boolean>}
   */
  @action.bound
  syncAuthorizationUser = async (syncUserAttribute: boolean = true) => {
    return Auth.currentAuthenticatedUser()
      .then(async data => {
        this.setIsAuthenticated(true);
        if (syncUserAttribute) {
          await API.get('user', `/api/v1/user/${data.attributes.sub}`)
            .then(res => {
              this.setApiResponse(res);
            })
            .catch(error => {
              if (error !== 'No current user')
                throw new ApiError(error.message);
            });
        }
        return true;
      })
      .catch(error => {
        if (error instanceof ApiError) throw error;
        this.initialize();
        return false;
      });
  };

  @action.bound
  setIsAuthenticated = (v: boolean) => {
    this.isAuthenticated = v;
  };

  @action.bound
  setSub = (v: string) => {
    this.sub = v;
  };
  @action.bound
  setEmail = (v: string) => {
    this.email = v;
  };
  @action.bound
  setGivenName = (v: string) => {
    this.givenName = v;
  };
  @action.bound
  setFamilyName = (v: string) => {
    this.familyName = v;
  };
  @action.bound
  setStartupRelationship = (v: string) => {
    this.startupRelationship = v;
  };
  @action.bound
  setCompanyName = (v: string) => {
    this.companyName = v;
  };
  @action.bound
  setAccountId = (v: string) => {
    this.accountId = v;
  };
  @action.bound
  setZipcode = (v: string) => {
    this.zipcode = v;
  };
  @action.bound
  setJobRole = (v: string) => {
    this.jobRole = v;
  };
  @action.bound
  setCountry = (v: string) => {
    this.country = v;
  };
  @action.bound
  setCompanyScale = (v: string) => {
    this.companyScale = v;
  };
  @action.bound
  setIndustry = (v: string) => {
    this.industry = v;
  };
  @action.bound
  setConsumption = (v: string) => {
    this.consumption = v;
  };
  @action.bound
  setJobPosition = (v: string) => {
    this.jobPosition = v;
  };
  @action.bound
  setPhone = (v: string) => {
    this.phone = v;
  };
  @action.bound
  setDeveloperState = (v: string) => {
    this.developerState = v;
  };
  @action.bound
  setIsApprovedUser = (v: boolean) => {
    this.isApprovedUser = v;
  };
  @action.bound
  setCheckInSignature = (v: string) => {
    this.checkInSignature = v;
  };

  @action.bound
  initialize = () => {
    this.setIsAuthenticated(false);
    this.setSub('');
    this.setEmail('');
    this.setGivenName('');
    this.setFamilyName('');
    this.setStartupRelationship('');
    this.setCompanyName('');
    this.setAccountId('');
    this.setZipcode('');
    this.setJobRole('');
    this.setCountry('');
    this.setCompanyScale('');
    this.setIndustry('');
    this.setConsumption('');
    this.setJobPosition('');
    this.setPhone('');
    this.setDeveloperState('');
    this.setIsApprovedUser(false);
    this.setCheckInSignature('');
  };

  @action.bound
  setApiResponse = (v: UserSessionApiResponse) => {
    try {
      this.setSub(v.sub);
      this.setEmail(v.email);
      this.setIsAuthenticated(v.sub !== ''); // subが取得できている場合は認証されているとみなす
      this.setGivenName(v.givenName);
      this.setFamilyName(v.familyName);
      this.setStartupRelationship(v.startupRelationship);
      this.setCompanyName(v.companyName);
      this.setAccountId(v.accountId);
      this.setZipcode(v.zipcode);
      this.setJobRole(v.jobRole);
      this.setCountry(v.country);
      this.setCompanyScale(v.companyScale);
      this.setIndustry(v.industry);
      this.setConsumption(v.consumption);
      this.setJobPosition(v.jobPosition);
      this.setPhone(v.phone);
      this.setDeveloperState(v.developerState);
      this.setIsApprovedUser(v.isApprovedUser);
      this.setCheckInSignature(v.checkInSignature);
    } catch (e) {
      // TODO: 雑に握りつぶしているので正しくハンドリングする
      console.log(e);
    }
  };
}
