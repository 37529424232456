// @flow
import React, { useContext, useState } from 'react';
import { type RouterHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { I18n } from 'aws-amplify';
import useAaeSlotList from '../../hooks/aae';
import ScrollToTop from '../../utils/ScrollToTop';
import DateUtil from '../../utils/Date';
import { AAE_RESERVATION_LIMIT, AAE_DURATION } from '../../const/index';
import { AppBar, Tabs, Tab, Grid, Typography } from '@material-ui/core';
import Loading from '../../components/common/Loading';
import UserSession from '../../stores/UserSession';
import { withStyles } from '@material-ui/core/styles';
import { withCloseNavigationBar } from '../../components/common/CloseNavigationBar';
import ReservationSlot from './ReservationSlot';
import Appointment from './Appointment';
import {
  ReserveAAEContext,
  DispatchContext,
  reducer,
  initialState
} from './context';
import CancelIcon from '@material-ui/icons/Cancel';
type Props = {
  classes: Object,
  userSession: UserSession,
  history: RouterHistory
};

const Component = (props: Props) => {
  const { classes, userSession, history } = props;
  const state = useContext(ReserveAAEContext);
  const dispatch = useContext(DispatchContext);
  const [tabValue, setTabValue] = useState(initialState.targetUnixTime);
  const [detailInputId, setDetailInputId] = useState(null);
  useAaeSlotList(userSession, history, state);

  const handleChange = (e, value) => {
    setTabValue(value);
    setDetailInputId(null);
    dispatch({
      type: 'SET_LOADING_STATE',
      isLoading: true
    });
    dispatch({
      type: 'SET_TARGET_UNIX_TIME',
      targetUnixTime: value
    });
  };

  const getSlotEventClassName = type => {
    return classes[type] ? classes[type] : classes['noEmpty'];
  };

  const getSlotEventName = slotEventName => {
    return slotEventName !== null ? slotEventName : I18n.get('reserve').noEmpty;
  };

  const targetDateAppointments = state.appointments.filter(
    appointment =>
      appointment.time >= state.targetUnixTime &&
      appointment.time < state.targetUnixTime + 24 * 3600
  );
  const availableReservationCount =
    AAE_RESERVATION_LIMIT -
    state.appointments.filter(
      appointment =>
        appointment.time + AAE_DURATION >= DateUtil.getCurrentUnixTime()
    ).length;

  return (
    <div className={classes.root}>
      <ScrollToTop />
      <Grid container justify="center" alignItems="flex-start">
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={12}>
              <AppBar
                position="static"
                color="inherit"
                square={false}
                className={classes.tabBar}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="off"
                >
                  {[...Array(7)].map((_, i) => {
                    const unixTime = DateUtil.getDateUnixTime(i);
                    // NOTE: Exclude Saturday and Sunday
                    if (DateUtil.isWeekend(unixTime) === false) {
                      return (
                        <Tab
                          key={i}
                          label={
                            <>
                              <Typography
                                variant="h3"
                                gutterBottom
                                align="center"
                                color="secondary"
                                className={classes.textWhite}
                              >
                                {DateUtil.createEngDateStr(
                                  DateUtil.getDateUnixTime(i)
                                )}
                              </Typography>
                              {state.slotEvents.length > 0 &&
                                state.slotEvents[i].map((slotEvent, index) => (
                                  <Typography
                                    key={index}
                                    variant="h4"
                                    gutterBottom
                                    align="center"
                                    className={getSlotEventClassName(
                                      slotEvent.type
                                    )}
                                  >
                                    {getSlotEventName(slotEvent.eventName)}
                                  </Typography>
                                ))}
                            </>
                          }
                          value={unixTime}
                          className={classes.tab}
                          disabled={state.isLoading || unixTime === tabValue}
                        ></Tab>
                      );
                    }
                  })}
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justify="center" alignItems="flex-start">
        <Grid item xs={12} className={classes.item}>
          <Typography variant="h1" align="center" className={classes.textWhite}>
            {DateUtil.createJpnDateStr(state.targetUnixTime)}{' '}
            {I18n.get('reserve').reservationSlot}
          </Typography>
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={10} className={classes.item}>
          <Typography variant="h3" align="center" className={classes.textWhite}>
            {I18n.get('reserve').reserveDescription1}
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>

        {state.isLoading === true && (
          <Grid item xs={12}>
            <Loading />
          </Grid>
        )}

        {state.isLoading === false && (
          <Grid
            item
            xs={12}
            sm={7}
            style={{
              marginTop: '10px',
              backgroundColor: '#001F3F',
              padding: '15px'
            }}
          >
            <Grid container justify="center">
              <Grid item xs={12}>
                <Typography variant="h4" align="center" color="secondary">
                  {I18n.get('reserve').reservationRemain}{' '}
                  {availableReservationCount} 件
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  align="center"
                  color="secondary"
                  className={classes.textWhite}
                >
                  {I18n.get('reserve').reserveDescription2}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {state.isLoading === false && targetDateAppointments.length === 0 && (
          <Grid item xs={12} className={classes.item}>
            <Grid container justify="center">
              <Grid item xs={12}>
                {state.slots.length > 0 ? (
                  state.slots.map((slot, index) => {
                    const slotDetailInputId = `${slot.type}_${slot.typeVariant}_${slot.time}`;
                    if (
                      detailInputId === null ||
                      detailInputId === slotDetailInputId
                    ) {
                      return (
                        <ReservationSlot
                          key={index}
                          history={history}
                          slot={slot}
                          userSession={userSession}
                          isInputVisible={detailInputId === slotDetailInputId}
                          status={
                            availableReservationCount !== 0
                              ? 'active'
                              : 'inactive'
                          }
                          setDetailInputId={() => {
                            if (detailInputId === slotDetailInputId) {
                              setDetailInputId(null);
                            } else {
                              setDetailInputId(slotDetailInputId);
                            }
                          }}
                        />
                      );
                    }
                  })
                ) : (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item xs={1} className={classes.item}>
                      <Typography variant="h1" color="secondary" align="center">
                        <CancelIcon />
                      </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                      <Typography
                        variant="h2"
                        gutterBottom
                        color="secondary"
                        align="center"
                      >
                        {I18n.get('reserve').noSlotDescription}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}

        {state.isLoading === false && targetDateAppointments.length > 0 && (
          <Grid item xs={12} className={classes.item}>
            <Grid container justify="center">
              <Grid item xs={12}>
                {targetDateAppointments.map((appointment, index) => (
                  <Appointment
                    key={index}
                    history={history}
                    appointment={appointment}
                    userSession={userSession}
                  ></Appointment>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export const ReserveAskAnExpertContainer = inject('userSession')(
  observer((props: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    return (
      <ReserveAAEContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <Component {...props} />
        </DispatchContext.Provider>
      </ReserveAAEContext.Provider>
    );
  })
);

const styles = theme => ({
  root: {
    backgroundColor: '#192534',
    maxWidth: '100%',
    minHeight: '90vh',
    flexGrow: 1
  },
  title: {
    color: '#000000',
    padding: '10px'
  },
  tabBar: {
    backgroundColor: '#092033',
    marginBottom: '10px',
    paddingTop: '10px'
  },
  tab: {
    flexShrink: 0,
    alignItems: 'baseline',
    opacity: '1 !important',
    backgroundColor: '#092033',
    minWidth: '204px',
    padding: '0px 2px 0px 2px',
    '&:hover': {
      opacity: '0.8 !important'
    }
  },
  textWhite: {
    color: '#FFF'
  },
  item: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  aae: {
    color: '#00FA9A',
    textTransform: 'none'
  },
  custom: {
    color: '#FF5A62',
    textTransform: 'none'
  },
  reserved: {
    color: '#F2941B',
    textTransform: 'none'
  },
  noEmpty: {
    color: '#CCC',
    textTransform: 'none'
  }
});

export const WithStyleContainer = withStyles(styles)(
  ReserveAskAnExpertContainer
);

export default withCloseNavigationBar(WithStyleContainer, { isWhite: false });
