// @flow
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import Error from '../../components/common/Error';
import Loading from '../../components/common/Loading';
import EmptyEvent from '../../components/event/EmptyEvent';
import Event from '../../components/event/Event';

export const useEvent = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [events, setEvents] = useState([]);
  let isMounted = false;

  useEffect(() => {
    isMounted = true;
    API.get('events', '/api/v1/events', {})
      .then(res => {
        if (isMounted) {
          if (res.events) {
            setEvents(res.events);
          } else {
            setEvents([]);
          }
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
        if (isMounted) {
          setLoading(false);
          setError(true);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);
  return { loading, error, events };
};

const EventContainer = () => {
  const { loading, error, events } = useEvent();
  if (loading) return <Loading />;
  if (error) return <Error />;
  if (events.length === 0) return <EmptyEvent />;
  return <Event events={events} />;
};

export default EventContainer;
