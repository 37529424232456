// @flow
import React from 'react';
import AccessComponent from '../../components/access/Access';
import AnnouncementContainer from '../common/Announcement';
import { withCloseNavigationBar } from '../../components/common/CloseNavigationBar';

const AccessContainer = () => {
  return (
    <>
      <AccessComponent />
      <AnnouncementContainer />
    </>
  );
};

export default withCloseNavigationBar(AccessContainer, { isWhite: false });
