// @flow
import { createContext } from 'react';
import type {
  ReserveAAEState,
  Slot,
  SlotEvent,
  Appointment
} from '../../../type/aae';
import DateUtil from '../../../utils/Date';

type Action =
  | {
      type: 'SET_APPOINTMENTS',
      appointments: Appointment[]
    }
  | {
      type: 'SET_SLOT_EVENTS',
      slotEvents: SlotEvent[][]
    }
  | {
      type: 'SET_SLOTS',
      slots: Slot[]
    }
  | {
      type: 'SET_LOADING_STATE',
      isLoading: boolean
    }
  | {
      type: 'SET_TARGET_UNIX_TIME',
      targetUnixTime: number
    };

function reducer(state: ReserveAAEState, action: Action) {
  switch (action.type) {
    case 'SET_APPOINTMENTS': {
      return { ...state, appointments: action.appointments };
    }
    case 'SET_SLOT_EVENTS': {
      return { ...state, slotEvents: action.slotEvents };
    }
    case 'SET_SLOTS': {
      return { ...state, slots: action.slots };
    }
    case 'SET_LOADING_STATE': {
      return { ...state, isLoading: action.isLoading };
    }
    case 'SET_TARGET_UNIX_TIME': {
      return { ...state, targetUnixTime: action.targetUnixTime };
    }
    default: {
      return state;
    }
  }
}

// NOTE: 土曜日もしくは日曜日に画面を表示した場合、次の月曜日を表示対象とする。
let nextWeekDayUnixTime = DateUtil.getDateUnixTime();
for (let i = 1; i < 7; i++) {
  if (DateUtil.isWeekend(nextWeekDayUnixTime)) {
    nextWeekDayUnixTime = DateUtil.getDateUnixTime(i);
  } else {
    break;
  }
}

export const initialState: ReserveAAEState = {
  appointments: [],
  slots: [],
  slotEvents: [],
  isLoading: true,
  targetUnixTime: nextWeekDayUnixTime
};
const ReserveAAEContext = createContext<ReserveAAEState>(initialState);
// flowでReact.Dispatchが解決できないため、やむなくanyで回避
// const DispatchContext = createContext<React.Dispatch<Action>>();
const DispatchContext = createContext<any>();

export { ReserveAAEContext, DispatchContext, reducer };
