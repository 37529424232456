// @flow
import React, { Component } from 'react';

type Props = {
  maxFontSize: number,
  children: any
};

type State = {
  fontSize: string
};

// TODO: util 化、もしくは Container Component へ移行
class EventTitle extends Component<Props, State> {
  ref: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      fontSize: '1px'
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.setState({
      fontSize:
        this.getFontSize(this.ref.current, this.props.maxFontSize) + 'px'
    });
  }

  getFontSize(el: any, maxFontSize: number) {
    let fontSize = 1;
    let complete = false;
    while (!complete) {
      el.style.fontSize = ''.concat(fontSize.toString(), 'px');
      const overflowWidth = el.clientWidth < el.scrollWidth;
      const overflowHeight = el.clientHeight < el.scrollHeight;

      if (overflowHeight || overflowWidth) {
        fontSize -= 1;
        complete = true;
      } else if (fontSize >= maxFontSize) {
        fontSize = maxFontSize;
        complete = true;
      } else if (!complete) {
        fontSize += 1;
      }
    }
    if (fontSize <= 0) fontSize = 1;
    return fontSize;
  }

  render() {
    const style = {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      textAlign: 'center',
      fontFamily: 'HiraKakuProN-W6',
      color: '#283C50',
      fontSize: this.state.fontSize
    };
    return (
      <div style={style} ref={this.ref}>
        {this.props.children}
      </div>
    );
  }
}
export default EventTitle;
