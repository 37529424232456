// @flow
import React from 'react';
import { Auth, I18n } from 'aws-amplify';
import { withSnackbar } from 'notistack';
import { inject, observer } from 'mobx-react';
import {
  Authenticator,
  ForgotPassword as AmplifyForgotPassword
} from 'aws-amplify-react';
import ForgotPassword from '../../components/forgot-password/ForgotPassword';
import { withCloseNavigationBar } from '../../components/common/CloseNavigationBar';

// TODO: Function Component 化
// TODO: Amplify の ForgotPassword を継承しない形にする

@inject('userSession')
@observer
class ForgotPasswordContainer extends AmplifyForgotPassword {
  constructor() {
    super();
    this.state = {
      snackKeys: []
    };
    this.customForgotPassword = this.customForgotPassword.bind(this);
  }

  componentWillUnmount() {
    const { closeSnackbar } = this.props;
    this.state.snackKeys.forEach(key => {
      closeSnackbar(key);
    });
  }

  customForgotPassword: () => void;
  customForgotPassword() {
    const { authData = {}, history } = this.props;
    const username = this.inputs.username || authData.username;
    if (!Auth || typeof Auth.forgotPassword !== 'function') {
      throw new Error(
        'No Auth module found, ' + 'please ensure @aws-amplify/auth is imported'
      );
    }
    this.setState({ loading: true });
    Auth.forgotPassword(username)
      .then(data => {
        this.props.enqueueSnackbar(I18n.get('forgotPassword').sentCode, {
          variant: 'success'
        });
        this.setState({ delivery: data.CodeDeliveryDetails });
        history.push({
          pathname: '/complete_password',
          state: { username: username }
        });
      })
      .catch(err => {
        const error = Object.prototype.hasOwnProperty.call(err, 'message')
          ? err.message
          : err;
        const key = this.props.enqueueSnackbar(I18n.get(error), {
          variant: 'error'
        });
        this.setState({ loading: false });
        this.setState({ snackKeys: this.state.snackKeys.concat(key) });
      });
  }

  render() {
    const { history, userSession } = this.props;
    return (
      <Authenticator
        authState="signIn"
        hideDefault={true}
        onStateChange={authState => {
          if (authState === 'signedIn') {
            userSession.syncAuthorizationUser();
            history.push('/');
          }
        }}
      >
        <ForgotPassword
          loading={this.state.loading}
          customForgotPassword={this.customForgotPassword}
          handleInputChange={this.handleInputChange}
          userName={this.inputs['username']}
        />
      </Authenticator>
    );
  }
}

export default withCloseNavigationBar(withSnackbar(ForgotPasswordContainer), {
  isWhite: false
});
