// @flow
import { useEffect, useContext } from 'react';
import { type RouterHistory } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import type { ReserveAAEState } from '../../type/aae';
import type { UserSessionType } from '../../stores/UserSession';
import { DispatchContext } from '../../containers/aae/context';
import { AaeApi } from '../../api/aae';
import DateUtil from '../../utils/Date';

const useAaeSlotList = (
  userSession: UserSessionType,
  history: RouterHistory,
  state: ReserveAAEState
) => {
  const dispatch = useContext(DispatchContext);

  useEffect(() => {
    if (state.isLoading) {
      // NOTE: 別タブでデータ更新した場合など、念のためユーザー情報を最新化している
      userSession
        .syncAuthorizationUser()
        .then(async () => {
          if (userSession.isApprovedUser === false) {
            history.push('/');
            return;
          }

          const appointmentResponse = await AaeApi.getAppointments(
            userSession.sub
          );
          const appointments = appointmentResponse.appointments;

          const slotResponse = await AaeApi.fetchSlots();
          const currentUnixTime = DateUtil.getCurrentUnixTime();
          const currentDateUnixTime = DateUtil.getDateUnixTime();

          // NOTE: 現在時刻の 1 時間前に相談枠は無効とする。
          let startTime =
            state.targetUnixTime === currentDateUnixTime
              ? currentUnixTime + 3600
              : state.targetUnixTime;
          const slots = slotResponse.slots.filter(
            slot =>
              slot.time >= startTime &&
              slot.time < state.targetUnixTime + 24 * 3600 &&
              slot.remain > 0
          );

          const events = [];
          for (let i = 0; i < 7; i++) {
            const dateUnixTime = DateUtil.getDateUnixTime(i);
            // NOTE: 現在時刻の 1 時間前に相談枠は無効とする。
            startTime =
              dateUnixTime === currentDateUnixTime
                ? currentUnixTime + 3600
                : dateUnixTime;
            const targetDaySlots = slotResponse.slots.filter(
              slot =>
                slot.time >= startTime &&
                slot.time < dateUnixTime + 3600 * 24 &&
                slot.remain > 0
            );
            let event = [];
            if (
              appointmentResponse.appointments.some(
                appointment =>
                  appointment.time >= dateUnixTime &&
                  appointment.time < dateUnixTime + 3600 * 24
              )
            ) {
              // NOTE: 予約済みの場合はイベント名として予約済みと表示する。
              event = [
                {
                  type: 'reserved',
                  eventName: I18n.get('reserve').reserved
                }
              ];
            } else {
              // NOTE: 予約していない日の場合はイベント名を抽出する。
              event = targetDaySlots
                .filter((slot, index, self) => {
                  return (
                    self.findIndex(_slot => {
                      return (
                        slot.type === _slot.type &&
                        slot.eventName === _slot.eventName
                      );
                    }) === index
                  );
                })
                .map(slot => {
                  return {
                    type: slot.type,
                    eventName: slot.eventName
                  };
                });
            }
            if (event.length === 0) {
              event = [
                {
                  type: null,
                  eventName: null
                }
              ];
            }
            events.push(event);
          }

          dispatch({
            type: 'SET_APPOINTMENTS',
            appointments: appointments
          });
          dispatch({
            type: 'SET_SLOTS',
            slots: slots
          });
          dispatch({
            type: 'SET_SLOT_EVENTS',
            slotEvents: events
          });
          dispatch({
            type: 'SET_LOADING_STATE',
            isLoading: false
          });
          dispatch({
            type: 'SET_TARGET_UNIX_TIME',
            targetUnixTime: state.targetUnixTime
          });
        })
        .catch(() => history.push('/'));
    }
  }, [state.isLoading]);
};

export default useAaeSlotList;
