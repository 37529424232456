// @flow
import React from 'react';
import { Auth, I18n } from 'aws-amplify';
import { withSnackbar } from 'notistack';
import { inject, observer } from 'mobx-react';
import { SignIn as AmplifySignIn } from 'aws-amplify-react';
import SignIn from '../../components/signin/SignIn';
import { withCloseNavigationBar } from '../../components/common/CloseNavigationBar';
import { TAB_INDEX } from '../../const/';

// TODO: Function Component 化
// TODO: Amplify の SignIn を継承しない形にする

@inject('signUpFormValue', 'userSession', 'topViewTab')
@observer
class SignInContainer extends AmplifySignIn {
  _isMounted: boolean;
  constructor() {
    super();
    this.state = {
      loading: false
    };
    this.customSignIn = this.customSignIn.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this._isMounted = true;
  }

  componentDidMount: () => void;
  componentDidMount() {
    const { signUpFormValue } = this.props;
    this.inputs['username'] = signUpFormValue.email;
    this.inputs['password'] = signUpFormValue.password;
  }

  componentWillUnmount: () => void;
  componentWillUnmount() {
    this._isMounted = false;
  }
  // NOTE: errorを統一するためにカスタマイズ。
  customSignIn: (event: any) => void;
  async customSignIn(event) {
    const {
      enqueueSnackbar,
      history,
      userSession,
      signUpFormValue,
      topViewTab
    } = this.props;
    event.preventDefault();
    this.setState({ loading: true });
    userSession.initialize();
    const { username = '', password = '' } = this.inputs;
    userSession.setEmail(username);
    try {
      await Auth.signIn(username, password);
      signUpFormValue.initialize();
      topViewTab.setSelected(TAB_INDEX.HOME);
      history.push('/');
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        // NOTE: 確認コードのページに遷移する
        history.push('/confirm_signup');
      } else if (err.code === 'InvalidParameterException') {
        enqueueSnackbar(I18n.get('signIn').emptyPassword, {
          variant: 'error'
        });
      } else {
        const error = Object.prototype.hasOwnProperty.call(err, 'message')
          ? err.message
          : err;
        enqueueSnackbar(I18n.get(error), {
          variant: 'error'
        });
      }
    } finally {
      this._isMounted && this.setState({ loading: false });
    }
  }

  render() {
    const { signUpFormValue } = this.props;
    return (
      <SignIn
        loading={this.state.loading}
        customSignIn={this.customSignIn}
        handleInputChange={this.handleInputChange}
        email={signUpFormValue.email || ''}
        password={signUpFormValue.password || ''}
      />
    );
  }
}

export default withCloseNavigationBar(withSnackbar(SignInContainer), {
  isWhite: false
});
