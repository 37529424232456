export const faqs = {
  ja: [
    {
      question: 'AWS Startup Loft Tokyo の場所を教えてください',
      answer:
        'AWS Startup Loft Tokyo は、東京都品川区上大崎3-1-1 目黒セントラルスクエア内にあります。'
    },
    {
      question: 'AWS Startup Loft Tokyo のオープン時間を教えてください',
      answer:
        'AWS Startup Loft Tokyo は、土日祝祭日を除く月曜日から金曜日の午前 10 時から午後 6 時まで' +
        'オープンしています。なお、夜間に行われるセミナーやイベントの開催日においては、この限りではありません。<br /><br />' +
        'また、やむを得ない事情により、オープン時間が変更または、終日クローズとなる場合もあります。' +
        'ご利用にあたって、ウェブサイト上の<a href="https://aws.amazon.com/jp/start-ups/loft/tokyo/" ' +
        'target="_blank">AWS Startup Loft Tokyo からのお知らせ情報</a>をご確認ください。'
    },
    {
      question: 'AWS Startup Loft Tokyo の利用方法を教えてください',
      answer:
        'AWS Startup Loft Tokyo は、AWS を利用中のスタートアップ企業およびデベロッパーの方々向けの設備です。<br /><br />' +
        'コ・ワーキングエリアをご利用頂くには、初回入館前に専用アプリケーションでの利用登録が必要となります。' +
        'AWS のアカウントと AWS Loft App はそれぞれ別のものとなりますのでご注意ください。<br /><br />' +
        'AWS Startup Loft Tokyo で開催する各種セミナーには、AWS アカウント ID をお持ちでない方でもご参加頂けますが、' +
        '各セミナーの参加登録ページから、事前の参加登録が必要になります。詳しくは、<a href="https://aws.amazon.com/jp/start-ups/loft/tokyo/events/" target="_blank">' +
        'セミナー情報ページ</a>をご覧下さい。<br /><br />' +
        'AWS Loft に滞在頂く間は、ステッカー式のバッジを、見やすい場所に着用頂きます。' +
        'このバッジの有効期間は 1 日です。退館時に受付横の回収ボックスに廃棄下さいますようお願いします。<br /><br />' +
        'AWS Loft ユーザーが利用できるスペースは、AWS Loft (17F) 内のみとなります。その他の施設への立ち入りはご遠慮ください。' +
        '発見した場合は、お声がけいただくとともに、場合によっては、AWS Loft からの退館をお願いし、今後の利用をお断りする場合があります。'
    },
    {
      question: 'AWS Loft App の登録・利用方法を教えてください',
      answer:
        'AWS Loft App で初回来場前に必ず事前登録をお済ませの上、お越しください。未登録の場合、入館できませんのでご注意ください。<br /><br />' +
        '初回来場時、アプリケーションへログイン後に表示される数字を、ビル 3F の警備員にご提示の上 17F Loft に入館いただきます。' +
        '17F 受付にて数字画面をご提示いただき承認をお待ちください。<br /><br />' +
        '承認後に画面を更新いただくと、番号が QR コードに切り替わりますのでチェックイン端末にかざします。' +
        '受付に身分証（名刺、または第三者機関発行の身分証）を提示の上、入館パスを受け取りご入場ください。' +
        '退館時に受付横の回収ボックスに廃棄下さいますようお願いします。<br /><br />' +
        '2 回目以降の来場時は、ビル 3F ゲート前の警備員にログイン後に表示される QR コードをご提示の上' +
        '17F Loft にお越しください。チェックイン端末で QR コードをかざし、受付にて身分証（名刺、または第三者機関発行の身分証）を提示の上、' +
        '入館パスを受け取りご入場ください。退館時に受付横の回収ボックスに廃棄下さいますようお願いします。<br /><br />' +
        '入館時に渡されるパスは Loft 滞在時間中は必ず着用をお願いします。未着用の場合、警備員、またはスタッフより適宜お声がけさせていただきます。'
    },
    {
      question: 'AWS Loft App で表示される QR コードについて',
      answer:
        'QR コードは一定時間ごとに切り替わります。スクリーンショットでのご入館はエラーとなりチェックインが出来ませんので都度ログインの上ご利用ください。<br />' +
        '本アプリをモバイル端末のホーム画面に保存いただくことをおすすめいたします。'
    },
    {
      question:
        'AWS Startup Loft Tokyo へ知人を連れていき打ち合わせに利用できますか？',
      answer:
        'AWS Startup Loft Tokyo は貸し会議室ではございません。すでに AWS のアカウントをお持ちの' +
        'スタートアップ・デベロッパーの方向けの施設となりますので、AWS アカウントをお持ちでない方を' +
        '伴ってのご来場はご遠慮いただけますようお願いします。'
    },
    {
      question: 'Ask an Expert はどのように利用できますか？',
      answer:
        'Ask an Expert に、AWS ソリューションアーキテクトやサポートエンジニアが待機している場合、' +
        'お気軽にお声がけください。相談開始前に簡単なアンケートにご回答いただくだけで技術相談対応をさせていただきます。<br /><br />' +
        '混雑時は、ホワイトボードにウェイティングリストとしてお名前をご記述ください。' +
        '時間帯によっては対応者がいない場合もございますので、その際は時間を改めて Ask an Expert コーナーにお越しください。'
    },
    {
      question:
        '特定の領域に詳しいソリューションアーキテクトがいる日程を教えてください。 ',
      answer:
        'Ask an Expert では、AWS のサービスに精通したソリューションアーキテクトが待機しておりますので、サービスに特化した日は現在設けておりません。'
    },
    {
      question: 'Wi-Fi 情報はどこに記載していますか？',
      answer: '入館時にお渡ししたステッカーに記載されています。'
    },
    {
      question: '会議室を借りることはできますか？',
      answer:
        '1室（定員6名）を1時間単位で無料でご利用が可能です。' +
        '予約の受付はしていませんので、当日受付にて会議室を利用したい旨、お申し出くださいませ。' +
        '皆様にご利用いただくため、ご希望いただいた時間帯に利用できない場合があることをご承知ください。'
    },
    {
      question: 'AWS Loft を見学したいのですが、ツアーは実施していますか？',
      answer: '見学ツアーの実施はしておりません。'
    },
    {
      question: '法人登記時、AWS Loftを利用すること可能でしょうか？',
      answer: '法人登記用の会社住所としてご利用いただくことはできません。'
    },
    {
      question: 'そのほかの質問はどこに聞けば良いですか?',
      answer:
        'AWS Startup Loft Tokyo のご質問やご相談は、' +
        '<a href="mailto:contact-aws-loft-tokyo@amazon.com">' +
        'contact-aws-loft-tokyo@amazon.com</a>までお寄せ下さい。'
    }
  ]
};
