// @flow
import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';
import { validator } from '../../utils/Validator';

const styles = theme => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #E0E4E8',
    borderRadius: '6.4px',
    marginTop: '16px',
    marginBottom: '8px'
  },
  disabled: {
    opacity: 0.5
  },
  textFieldInput: {
    color: '#000000'
  }
});

type Props = {
  classes: Object,
  name: string,
  value: string,
  label: string,
  handleInputChange: any => void,
  validatorListener: void => void
};

const PasswordInput = ({
  classes,
  name = '',
  value = '',
  label = '',
  handleInputChange = (e: any) => {},
  validatorListener = () => {}
}: Props) => (
  <TextValidator
    name={name}
    value={value}
    label={label}
    type={'password'}
    fullWidth
    onChange={handleInputChange}
    className={classes.textField}
    InputProps={{
      classes: {
        input: classes.textFieldInput
      }
    }}
    validators={validator('password').validationItems}
    errorMessages={validator('password').validationMessages}
    variant="filled"
    validatorListener={validatorListener}
  />
);

export default withStyles(styles)(PasswordInput);
