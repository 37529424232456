// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { Typography, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import loftJapan from '../../assets/img/LoftJapan.png';
import awsloft from '../../assets/img/LoftIconTokyo.png';

const styles = theme => ({
  root: {
    marginTop: '20px',
    backgroundColor: theme.palette.background.secondary,
    overflowX: 'hidden',
    overflowY: 'hidden',
    marginBottom: '20px'
  },
  signUpButton: {
    borderRadius: '28px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '15px',
    color: '#FFFFFF',
    letterSpacing: '2.4px',
    width: '311px',
    height: '56px'
  },
  signInButton: {
    borderRadius: '28px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '15px',
    color: theme.palette.text.hint,
    letterSpacing: '2.4px',
    width: '311px',
    height: '56px'
  },
  title: {
    paddingTop: '13px',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  topImage: {
    maxWidth: '100%'
  },
  description: {
    paddingLeft: '25px',
    paddingRight: '25px',
    marginBottom: '13px'
  }
});

type Props = {
  classes: Object
};

const Welcome = ({ classes }: Props) => (
  <div className={classes.root}>
    <Grid container spacing={24} justify="center">
      <Grid item>
        <img
          src={loftJapan}
          alt="AWS Startup Loft Tokyo"
          className={classes.topImage}
        />
      </Grid>
      <Grid container item justify="center">
        <img
          src={awsloft}
          height="63"
          width="262"
          alt="AWS Startup Loft Tokyo"
        />
      </Grid>
    </Grid>
    <Grid container spacing={16} justify="center">
      <Grid item>
        <Typography
          className={classes.title}
          variant="h2"
          gutterBottom
          align="center"
        >
          {I18n.get('home').welcome}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container justify="center">
          <Grid item xs={12} sm={10}>
            <Typography
              className={classes.description}
              variant="body1"
              align="center"
            >
              {I18n.get('home').description1}
              {I18n.get('home').description2}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justify="center">
          <Grid item>
            <Button
              data-testid="signInButton"
              className={classes.signInButton}
              variant="contained"
              component={Link}
              to="/signin"
            >
              {I18n.get('home').signIn}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(Welcome);
