// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ProfileInput from '../common/ProfileInput';
import ProfileSelect from '../common/ProfileSelect';
import ProfileButton from '../common/ProfileButton';
import { I18n } from 'aws-amplify';

const styles = theme => ({
  root: {
    color: '#000000',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '20px'
  },
  validatorForm: {
    width: '100%'
  },
  textLabel: {
    margin: '20px 4px 10px 4px'
  },
  textField: {
    border: '1px solid #E0E4E8',
    borderRadius: '6.4px',
    fontFamily: 'HiraKakuProN-W3',
    fontSize: '14px'
  },
  selectButtonForm: {
    width: '100%'
  },
  submitButton: {
    width: '311px',
    height: '56px',
    borderRadius: '28px',
    fontSize: '15px',
    color: '#FFFFFF',
    letterSpacing: '2.4px',
    fontFamily: 'HiraKakuProN-W6'
  },
  submitButtonField: {
    marginTop: '40px',
    marginBottom: '40px',
    position: 'relative'
  },
  buttonProgress: {
    color: '#ffffff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

type Props = {
  classes: Object,
  loading: boolean,
  options: Object,
  profile: Object,
  updateProfile: void => void,
  handleInputChange: any => void
};

const Profile = ({
  classes,
  loading = false,
  options = {},
  profile = {},
  updateProfile = () => {},
  handleInputChange = (e: any) => {}
}: Props) => (
  <Grid container spacing={0} className={classes.root}>
    <Grid item xs={12} justify="flex-start" container>
      <Typography variant="h1" gutterBottom>
        {I18n.get('profile').edit}
      </Typography>
    </Grid>
    <ValidatorForm onSubmit={updateProfile} className={classes.validatorForm}>
      <ProfileInput
        disabled={true}
        name={'email'}
        type={'email'}
        value={profile.email}
        placeholder={I18n.get('common').alphaNumeric}
      />
      <Grid item xs={12} justify="flex-start" container>
        <Typography
          variant="h5"
          color="primary"
          gutterBottom
          className={classes.textLabel}
        >
          {I18n.get('common').name}
        </Typography>
        <Grid item xs={12} justify="center" container>
          <Grid item xs={6} justify="center" container>
            <ProfileInput
              fieldOnly={true}
              name={'familyName'}
              value={profile.familyName}
              handleInputChange={handleInputChange}
              placeholder={I18n.get('common').familyName}
            />
          </Grid>
          <Grid item xs={6} justify="center" container>
            <ProfileInput
              fieldOnly={true}
              name={'givenName'}
              value={profile.givenName}
              handleInputChange={handleInputChange}
              placeholder={I18n.get('common').givenName}
            />
          </Grid>
        </Grid>
      </Grid>
      <ProfileInput
        name={'phone'}
        type={'tel'}
        value={profile.phone}
        handleInputChange={handleInputChange}
        placeholder={I18n.get('common').numeric + I18n.get('common').noHyphen}
      />
      <ProfileInput
        name={'zipcode'}
        type={'number'}
        value={
          // NOTE: サーバーサイドから返却される zipcode にハイフンが含まれるため View 側で置換
          // TODO: サーバーサイドの修正
          profile.zipcode ? profile.zipcode.replace('-', '') : ''
        }
        handleInputChange={handleInputChange}
        placeholder={I18n.get('common').numeric + I18n.get('common').noHyphen}
      />
      <ProfileSelect
        name={'country'}
        value={profile.country}
        options={options.country || []}
        handleInputChange={handleInputChange}
      />
      <ProfileInput
        name={'companyName'}
        value={profile.companyName}
        handleInputChange={handleInputChange}
        placeholder={I18n.get('profile').companyNameSample}
      />
      <ProfileSelect
        name={'companyScale'}
        value={profile.companyScale}
        options={options.companyScale || []}
        handleInputChange={handleInputChange}
      />
      <ProfileSelect
        name={'startupRelationship'}
        value={profile.startupRelationship}
        options={options.startupRelationship || []}
        handleInputChange={handleInputChange}
      />
      <ProfileInput
        name={'jobPosition'}
        value={profile.jobPosition}
        handleInputChange={handleInputChange}
        placeholder={I18n.get('profile').jobPositionSample}
      />
      <ProfileSelect
        name={'jobRole'}
        value={profile.jobRole}
        options={options.jobRole || []}
        handleInputChange={handleInputChange}
      />
      <ProfileSelect
        name={'industry'}
        value={profile.industry}
        options={options.industry || []}
        handleInputChange={handleInputChange}
      />
      <Grid item xs={12} justify="flex-start" container>
        <Typography
          variant="h5"
          color="primary"
          gutterBottom
          className={classes.textLabel}
        >
          {I18n.get('common').accountId}
          <Button
            target="_blank"
            href="https://console.aws.amazon.com/?nc2=h_m_mc"
            color="secondary"
          >
            {I18n.get('profile').openConsole}
          </Button>
        </Typography>
        <ProfileInput
          fieldOnly={true}
          name={'accountId'}
          type={'number'}
          value={profile.accountId}
          handleInputChange={handleInputChange}
          placeholder={I18n.get('common').numeric + I18n.get('common').noHyphen}
        />
      </Grid>
      <ProfileSelect
        name={'consumption'}
        value={profile.consumption}
        options={options.consumption || []}
        handleInputChange={handleInputChange}
      />
      <Grid item xs={12} justify="flex-start" container>
        <Typography
          variant="h5"
          color="primary"
          gutterBottom
          className={classes.textLabel}
        >
          {I18n.get('common').developerState}
        </Typography>
        <div className={classes.selectButtonForm}>
          <ProfileButton
            name={'developerState'}
            on={profile.developerState === 'Yes' ? true : false}
            value={'Yes'}
            displayValue={I18n.get('common').yes}
            handleInputChange={handleInputChange}
          />
          <ProfileButton
            name={'developerState'}
            on={profile.developerState === 'No' ? true : false}
            value={'No'}
            displayValue={I18n.get('common').no}
            handleInputChange={handleInputChange}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        justify="center"
        container
        className={classes.submitButtonField}
      >
        <Button
          variant="contained"
          color="secondary"
          className={classes.submitButton}
          disabled={loading}
          type="submit"
        >
          {I18n.get('profile').update}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Grid>
    </ValidatorForm>
  </Grid>
);

export default withStyles(styles)(Profile);
