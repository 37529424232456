// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Loading from '../../components/common/Loading';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

type Props = {
  classes: Object,
  isOpen: boolean,
  isLoading: Boolean,
  confirmText: String,
  loadingText: String,
  errorMessageText: String,
  doSignOut: () => void,
  doCancel: () => void,
  execute: () => void
};

const ConfirmDialog = ({
  classes,
  isOpen,
  isLoading,
  confirmText,
  loadingText,
  errorMessageText,
  execute = () => {},
  doCancel = () => {}
}: Props) => {
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      fullWidth={true}
    >
      <DialogContent color="secondary">
        <Typography classes={{ root: classes.dialogTypography }}>
          {confirmText}
        </Typography>
        <Typography classes={{ root: classes.errorDialogTypography }}>
          {errorMessageText}
        </Typography>
        {isLoading && (
          <div className={classes.loading}>
            <Loading text={loadingText} />
          </div>
        )}
      </DialogContent>
      {!isLoading && (
        <DialogActions classes={{ root: classes.dialogButton }}>
          <Button onClick={() => execute()} color="secondary">
            {I18n.get('common').yes}
          </Button>
          <Button onClick={() => doCancel()} color="secondary">
            {I18n.get('common').no}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const styles = theme => ({
  loading: {
    color: '#000'
  },
  dialogTitle: {
    fontFamily: 'HiraKakuProN-W6',
    background: '#283C50',
    fontSize: '14px',
    textAlign: 'center'
  },
  dialogTypography: {
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '14px',
    color: '#283C50',
    letterSpacing: '1.2px',
    marginTop: '15px',
    textAlign: 'center'
  },
  errorDialogTypography: {
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '14px',
    color: '#ff0000',
    letterSpacing: '1.2px',
    marginTop: '15px',
    textAlign: 'center'
  },
  dialogButton: {
    margin: theme.spacing.unit
  }
});

export default withStyles(styles)(ConfirmDialog);
