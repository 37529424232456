// @flow
import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { I18n } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import loftJapan from '../../assets/img/LoftJapan.png';
import awsloft from '../../assets/img/LoftIconTokyo.png';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.secondary,
    marginBottom: '40px',
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  button: {
    borderRadius: '28px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '15px',
    color: '#FFFFFF',
    letterSpacing: '2.4px',
    width: '311px',
    height: '56px'
  },
  textFieldContainer: {
    margin: '0px 20px'
  },
  textField: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #E0E4E8',
    borderRadius: '6.4px'
  },
  textFieldInput: {
    color: '#000000'
  },
  buttonProgress: {
    color: '#ffffff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  topImage: {
    maxWidth: 'min(420px, 100vw)',
    maxHeight: '142px'
  },
  wrapper: {
    position: 'relative'
  },
  footer: {
    height: '40px'
  }
});

type Props = {
  classes: Object,
  loading: boolean,
  customSignIn: (event: any) => void,
  handleInputChange: () => void,
  email: string,
  password: string
};

const SignIn = ({
  classes,
  loading = false,
  customSignIn = event => {},
  handleInputChange = () => {},
  email = '',
  password = ''
}: Props) => (
  <div
    className={classes.root}
    onKeyPress={ev => ev.key === 'Enter' && customSignIn(ev)}
  >
    <Grid container spacing={24} justify="center">
      <Grid container item justify="center">
        <img
          src={loftJapan}
          className={classes.topImage}
          alt="AWS Startup Loft Tokyo"
        />
      </Grid>
      <Grid container item justify="center">
        <img
          src={awsloft}
          height="63"
          width="262"
          alt="AWS Startup Loft Tokyo"
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.textFieldContainer}>
          <Typography variant="h5" align="left">
            {I18n.get('common').email}
          </Typography>
          <TextField
            name="username"
            label="Email"
            className={classes.textField}
            InputProps={{
              classes: {
                input: classes.textFieldInput
              }
            }}
            type="email"
            margin="normal"
            variant="filled"
            fullWidth
            defaultValue={email}
            onChange={handleInputChange}
            color="secondary"
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.textFieldContainer}>
          <Typography variant="h5" align="left">
            {I18n.get('signIn').password}
          </Typography>
          <TextField
            name="password"
            label="Password"
            className={classes.textField}
            InputProps={{
              classes: {
                input: classes.textFieldInput
              }
            }}
            type="password"
            margin="normal"
            variant="filled"
            fullWidth
            defaultValue={password}
            onChange={handleInputChange}
          />
        </div>
      </Grid>
      <Grid item>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={customSignIn}
            disabled={loading}
          >
            {I18n.get('signIn').signIn}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Grid>
      <Grid item>
        <Button
          component={Link}
          to="/forgot_password"
          className={classes.button}
        >
          {I18n.get('signIn').forgotPassword}
        </Button>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(SignIn);
