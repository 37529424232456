// @flow
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { I18n } from 'aws-amplify';

const styles = theme => ({
  root: {
    marginTop: '20px',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  error: {
    marginTop: '40px'
  },
  errorText: {
    color: 'inherit'
  },
  errorIcon: {
    color: '#E10050',
    marginRight: '-15px'
  }
});

type Props = {
  classes: Object,
  message: string
};

// TODO: Function Component への変更
class Error extends Component<Props> {
  render() {
    let { message, classes } = this.props;
    if (message === undefined) {
      message = I18n.get('common').error;
    }

    return (
      <div className={classes.root}>
        <div id="error" className={classes.error}>
          <Grid
            container
            spacing={24}
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Grid
                container
                spacing={24}
                justify="center"
                direction="row"
                alignItems="center"
              >
                <Grid item>
                  <ErrorOutlineIcon className={classes.errorIcon} />
                </Grid>
                <Grid item>
                  <Typography className={classes.errorText} variant="h5">
                    {message}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.errorText}>
                {I18n.get('common').retryLater}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Error);
