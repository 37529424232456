//
// @flow
//
import { action, observable } from 'mobx';

/* eslint no-invalid-this: 0 */
export default class TopViewTab {
  @observable selected: number = 0; // Home画面

  @action.bound
  setSelected = (v: number) => {
    this.selected = v;
  };
}
