// @flow
import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Menu from '../../components/other-menu/Menu';
import UserSession from '../../stores/UserSession';

type Props = {
  userSession: UserSession
};

const MenuContainer = inject('userSession')(
  observer(({ userSession }: Props) => {
    useEffect(() => {
      (async () => {
        await userSession.syncAuthorizationUser();
      })();
    });
    return <Menu isAuthenticated={userSession.isAuthenticated} />;
  })
);

export default MenuContainer;
