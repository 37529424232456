// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Chip,
  Divider,
  Grid
} from '@material-ui/core';
import EventTitle from './EventTitle';
import EventSeminar from '../../assets/img/EventSeminar.jpg';
import EventHandson from '../../assets/img/EventHandson.jpg';
import EventLT from '../../assets/img/EventLT.jpg';
import EventNetworking from '../../assets/img/EventNetworking.jpg';
import EventRoundTable from '../../assets/img/EventRoundTable.jpg';

const getEventName = (type: string) => {
  switch (type) {
    case 'セミナー':
      return EventSeminar;
    case 'ワークショップ':
    case 'ハンズオン':
    case 'ハンズオン・ワークショップ':
      return EventHandson;
    case 'ネットワーキング':
      return EventNetworking;
    case 'LT':
      return EventLT;
    case 'ラウンドテーブル':
      return EventRoundTable;
    default:
      return EventSeminar;
  }
};
const styles = theme => ({
  card: {
    width: '168px',
    height: '284px',
    marginBottom: '20px'
  },
  cardContent: {
    height: '120px',
    padding: '10px'
  },
  cardActions: {
    height: '46px',
    justifyContent: 'center',
    marginTop: '-2px'
  },
  cardActionButton: {
    textAlign: 'center',
    textColor: theme.palette.text.secondary
  },
  eventDate: {
    textAlign: 'center'
  },
  media: {
    height: '102px'
  },
  chip: {
    height: '20px',
    background: 'rgba(242,148,27,0.12)',
    borderRadius: '10px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '9px',
    color: '#F2941B',
    letterSpacing: '0.4px',
    margin: '2px'
  },
  chipLabel: {
    paddingLeft: '6px',
    paddingRight: '6px'
  },
  notApply: {
    color: '#ff2424'
  }
});

type Props = {
  classes: Object,
  event: {
    date: string,
    title: string,
    types: string[],
    link: string,
    state: string
  }
};

const EventCard = ({ classes, event }: Props) => {
  return (
    <Grid item xs={6} sm={6} md={4} lg={4} xl={3} container justify="center">
      <Card data-testid="event" className={classes.card}>
        <CardMedia
          className={classes.media}
          image={getEventName(event.types[0])}
          title="EventImage"
        />
        <CardContent className={classes.cardContent}>
          <div style={{ width: '150px', height: '60px' }}>
            <EventTitle maxFontSize={16}>{event.title}</EventTitle>
          </div>
          <Typography
            className={classes.eventDate}
            gutterBottom
            variant="body2"
          >
            {event.date}
          </Typography>
          {event.types.map((type, index) => (
            <Chip key={index} label={type} className={classes.chip} />
          ))}
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          {event.link !== null ? (
            <Button
              className="eventLink"
              target="_blank"
              href={event.link}
              size="small"
              color="secondary"
            >
              <Typography color="secondary" variant="h6" align="center">
                {I18n.get('event').apply}
              </Typography>
            </Button>
          ) : (
            <Typography
              className={classes.notApply}
              variant="h6"
              align="center"
            >
              {event.state}
            </Typography>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};
export default withStyles(styles)(EventCard);
