// @flow
import React from 'react';
import { Typography, withStyles, Grid, Paper, Link } from '@material-ui/core';
import { formatLinkToHtml } from '../../utils/FormatString';
import { I18n } from 'aws-amplify';

const styles = theme => ({
  announcements: {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '10px',
    marginRight: '10px'
  },
  reception: {
    marginTop: '20px',
    marginBottom: '10px',
    marginLeft: '10px',
    marginRight: '10px'
  },
  announcementsText: {
    color: '#000',
    padding: '10px'
  },
  header: {
    marginBottom: '10px',
    color: '#fff'
  },
  announcementsBoard: {
    marginTop: '20px',
    marginBottom: '40px',
    backgroundColor: '#141e30',
    padding: '10px 20px'
  }
});

type Props = {
  classes: Object,
  announcements: string[]
};

const Announcement = ({ classes, announcements = [] }: Props) => (
  <>
    <Grid container direction="row" justify="center">
      <Grid item xs={12}>
        {
          <Paper className={classes.announcementsBoard}>
            <Typography variant="h4" className={classes.header} align="center">
              {formatLinkToHtml(I18n.get('common').announcement, [
                'https://aws.amazon.com/jp/startups/lofts/tokyo-loft/'
              ])}
            </Typography>
            <Paper data-testid="announcement" className={classes.announcements}>
              <Typography variant="body1" className={classes.announcementsText}>
                新型コロナウィルス対策に伴い、目黒セントラルスクエアビル 17F の
                Loft は 2020 年 3 月 2
                日（月）より休館しております（再開日未定）。
                <br />
                再開の際は事前にご案内いたします。
                <br />
                ※コワーキングスペースおよび会場利用を含むイベントは原則実施しません。
              </Typography>
            </Paper>
            <Paper data-testid="announcement" className={classes.announcements}>
              <Typography variant="body1" className={classes.announcementsText}>
                Loft App への新規登録およびオンライン相談 (Online Ask An Expert)
                は当面の間休止いたします。再開の際はあらためてご案内をいたします。
                <br />
                AWS に関する技術的なご相談をご希望の際は、
                <Link
                  href={'https://aws.amazon.com/jp/contact-us/'}
                  color="secondary"
                  underline="always"
                  target="_blank"
                >
                  こちら
                </Link>
                の問い合わせページをご利用ください。
              </Typography>
            </Paper>

            {/* {announcements.map((announcement, index) => (
              <Paper
                data-testid="announcement"
                key={index}
                className={classes.announcements}
              >
                <Typography
                  variant="body1"
                  className={classes.announcementsText}
                >
                  {formatReturnToHtml(announcement)}
                </Typography>
              </Paper>
            ))} */}
          </Paper>
        }
      </Grid>
    </Grid>
  </>
);

export default withStyles(styles)(Announcement);
