// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import QRCode from 'qrcode.react';
import { Card, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import awsloft from '../../assets/img/LoftIconTokyo.png';

const styles = theme => ({
  root: {
    marginTop: '20px',
    backgroundColor: theme.palette.background.secondary,
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  card: {
    minWidth: '326px',
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center'
  },
  content: {
    textAlign: 'center',
    borderLeft: '4px solid #E0E4E8',
    borderRight: '4px solid #E0E4E8',
    marginTop: '4px',
    marginLeft: '4px',
    marginRight: '4px',
    marginBottom: '4px',
    width: '286px',
    height: '330px'
  },
  qrMessage: {
    marginTop: '20px',
    marginBottom: '54px',
    marginLeft: '44px',
    marginRight: '44px',
    paddingTop: '4px',
    height: '28px',
    width: '200px',
    background: '#F0F2F4',
    borderRadius: '14px',
    textAlign: 'center'
  },
  closedDescription: {
    fontWeight: 'bold',
    marginBottom: '10px'
  },
  messageBox: {
    backgroundColor: '#141E30',
    padding: '10px 10px',
    borderRadius: '10px'
  }
});

type Props = {
  classes: Object,
  qrValue: string
};

const CheckIn = ({ classes, qrValue }: Props) => (
  <div className={classes.root}>
    <Grid container spacing={24} justify="center">
      <Grid container item justify="center">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <div className={classes.qrMessage}>
              <Typography variant="h4" align="center">
                {I18n.get('home').qrCode}
              </Typography>
            </div>
            <QRCode
              data-testid="qrCode"
              renderAs="svg"
              fgColor="#283C50"
              size={184}
              value={JSON.stringify(qrValue)}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid container item justify="center">
        <img
          src={awsloft}
          height="63"
          width="262"
          alt="AWS Startup Loft Tokyo"
        />
      </Grid>
      <Grid item>
        <Grid container justify="center">
          <Grid item xs={10} sm={12} className={classes.messageBox}>
            <Typography
              variant="h2"
              align="center"
              color="secondary"
              gutterBottom
              className={classes.closedDescription}
            >
              {I18n.get('home').currentlyClosed}
            </Typography>
            <Typography variant="h6" gutterBottom align="center">
              {I18n.get('home').descriptionDuringClosed1}
            </Typography>
            <Typography variant="h6" gutterBottom align="center">
              {I18n.get('home').descriptionDuringClosed3}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(CheckIn);
