// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ProfileInput from '../common/ProfileInput';

import { I18n } from 'aws-amplify';

const styles = theme => ({
  root: {
    maxWidth: '100%',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    backgroundColor: theme.palette.background.paper
  },
  validatorForm: {
    marginTop: '10px',
    width: '100%'
  },
  warningLabel: {
    background: 'rgba(242,148,27,0.12)',
    border: '1px solid #A06828',
    borderRadius: '4px',
    fontFamily: 'HiraKakuProN-W3',
    fontSize: '11px',
    color: '#A06828',
    letterSpacing: '1.1px',
    lineHeight: '20px',
    padding: '20px',
    marginTop: '36px'
  },
  submitButtonField: {
    marginTop: '40px',
    marginBottom: '40px',
    position: 'relative'
  },
  submitButton: {
    borderRadius: '28px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '15px',
    letterSpacing: '2.4px',
    width: '311px',
    height: '56px',
    color: '#FFFFFF',
    position: 'relative'
  },
  footer: {
    height: '40px'
  },
  resendCode: {
    marginTop: '10px',
    fontFamily: 'HiraKakuProN-W6'
  },
  buttonProgress: {
    color: '#ffffff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

type Props = {
  classes: Object,
  loading: boolean,
  email: string,
  code: string,
  customConfirm: void => void,
  resendCode: void => void,
  handleInputChange: any => void
};

const ConfirmSignUp = ({
  classes,
  loading = false,
  email = '',
  code = '',
  customConfirm = () => {},
  resendCode = () => {},
  handleInputChange = (e: any) => {}
}: Props) => (
  <Grid container spacing={0} className={classes.root}>
    <Grid item xs={12} justify="flex-start" container>
      <Typography variant="h1" color="primary">
        {I18n.get('confirmSignUp').inputCode}
      </Typography>
    </Grid>
    <ValidatorForm onSubmit={customConfirm} className={classes.validatorForm}>
      <ProfileInput
        disabled={true}
        name={'email'}
        type={'email'}
        value={email}
        placeholder={I18n.get('common').alphaNumeric}
      />
      <ProfileInput
        name={'code'}
        type={'number'}
        value={code}
        handleInputChange={handleInputChange}
        placeholder={I18n.get('common').numeric}
      />
      <Grid item xs={12} justify="center" container>
        <Typography
          variant="h5"
          color="primary"
          gutterBottom
          className={classes.warningLabel}
        >
          {I18n.get('confirmSignUp').description}
        </Typography>
      </Grid>
      <Grid item xs={12} justify="flex-end" container>
        <Button
          color="secondary"
          className={classes.resendCode}
          onClick={resendCode}
        >
          {I18n.get('confirmSignUp').resendCode}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        justify="center"
        container
        className={classes.submitButtonField}
      >
        <Button
          variant="contained"
          color="secondary"
          className={classes.submitButton}
          disabled={loading}
          type="submit"
        >
          {I18n.get('confirmSignUp').confirm}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Grid>
    </ValidatorForm>
    <Grid
      item
      className={classes.footer}
      xs={12}
      direction="column"
      alignItems="center"
      container
    ></Grid>
  </Grid>
);

export default withStyles(styles)(ConfirmSignUp);
