// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import SlotRemainButton from './SlotRemainButton';
import { Button, Grid, Link, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  classes: Object,
  disabled: boolean,
  time: string,
  type: string,
  eventName: string,
  description: string,
  chimeId: string,
  detail: string,
  handleInputChange: () => void,
  setIsEditDialogOpen: boolean => void,
  setIsCancelDialogOpen: boolean => void
};

const Appointment = (props: Props) => {
  const {
    classes,
    disabled = false,
    time = '',
    type = '',
    eventName = '',
    description = '',
    chimeId = '',
    detail = '',
    handleInputChange = () => {},
    setIsEditDialogOpen = bool => {},
    setIsCancelDialogOpen = bool => {}
  } = props;
  return (
    <Grid container justify="center">
      <Grid item align="center" xs={12}>
        <SlotRemainButton
          status="reserved"
          time={time}
          type={type}
          eventName={eventName}
          description={description}
        ></SlotRemainButton>
        <Grid container justify="center">
          <Grid item xs={12} className={classes.item}>
            <Typography
              variant="h4"
              align="center"
              className={classes.textWhite}
            >
              <Link
                href={`https://chime.aws/${chimeId}`}
                color="secondary"
                underline="always"
                target="_blank"
              >
                {I18n.get('reserve').here}
              </Link>
              {I18n.get('reserve').meetingRoomNavigate}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <Typography
              variant="h3"
              align="center"
              className={classes.textWhite}
            >
              (Chime ID: {chimeId})
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.item + ' ' + classes.textWrapper}>
        <TextField
          placeholder={I18n.get('reserve').consultationContent}
          multiline
          rows="10"
          value={detail}
          variant="outlined"
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
              input: classes.textFieldInput
            }
          }}
          className={classes.textArea}
          onChange={handleInputChange}
          disabled={disabled}
        />
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            className={classes.btn + ' ' + classes.edit}
            onClick={() => setIsEditDialogOpen(true)}
            disabled={detail === '' || disabled}
          >
            {I18n.get('reserve').edit}
          </Button>
          <Button
            variant="contained"
            className={classes.btn + ' ' + classes.cancel}
            onClick={() => setIsCancelDialogOpen(true)}
            disabled={disabled}
          >
            {I18n.get('reserve').cancel}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  textWrapper: {
    margin: '0px 20px 0px 20px',
    color: '#fff',
    maxWidth: '880px'
  },
  textArea: {
    marginTop: '10px',
    width: '100%',
    backgroundColor: '#fff'
  },
  textFieldInput: {
    color: '#000',
    backgroundColor: '#fff'
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderStyle: 'none'
    }
  },
  cssFocused: {
    borderStyle: 'none'
  },
  notchedOutline: {
    borderStyle: 'none'
  },
  item: {
    marginTop: '5px',
    marginBottom: '5px'
  },
  textWhite: {
    color: '#FFF'
  },
  btn: {
    borderRadius: '14px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '12px',
    color: '#FFFFFF',
    letterSpacing: '2.4px',
    width: '100%',
    height: '30px',
    marginTop: '10px',
    marginRight: '0px',
    marginLeft: '15px',
    maxWidth: '120px'
  },
  cancel: {
    backgroundColor: '#dc004e !important',
    '&:hover': {
      backgroundColor: '#dc004e',
      opacity: 0.9
    }
  },
  edit: {
    backgroundColor: '#67ac5c !important',
    '&:hover': {
      backgroundColor: '#67ac5c',
      opacity: 0.9
    }
  }
});

export default withStyles(styles)(Appointment);
