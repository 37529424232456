// @flow
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export function LoftHomeIcon(props: any) {
  const svgpath =
    'M24.7574754,10.6664754 L13.4598689,0.372147541 C12.9639836,-0.0797704918 12.2161967,-0.0797213115 11.7205082,0.372098361 L0.422852459,10.6665246 C0.0256229508,11.0284918 -0.105590164,11.5861967 0.0884754098,12.0872951 C0.282590164,12.5883934 0.755213115,12.9121475 1.29260656,12.9121475 L3.09703279,12.9121475 L3.09703279,23.2262951 C3.09703279,23.6352295 3.42860656,23.9668033 3.83754098,23.9668033 L10.0300328,23.9668033 C10.4389672,23.9668033 10.770541,23.6352787 10.770541,23.2262951 L10.770541,16.9638689 L14.4099836,16.9638689 L14.4099836,23.2263443 C14.4099836,23.6352787 14.7415574,23.9668525 15.1504918,23.9668525 L21.3426885,23.9668525 C21.751623,23.9668525 22.0831967,23.6353279 22.0831967,23.2263443 L22.0831967,12.9121475 L23.8879672,12.9121475 C24.4253115,12.9121475 24.8979836,12.5883443 25.0920984,12.0872951 C25.285918,11.5861475 25.1547049,11.0284918 24.7574754,10.6664754 Z M21.8897705,1.51131148 L16.9167049,1.51131148 L22.6302787,6.70652459 L22.6302787,2.25177049 C22.6302787,1.84283607 22.2987541,1.51131148 21.8897705,1.51131148 Z';
  return (
    <SvgIcon {...props}>
      <path d={svgpath}></path>
    </SvgIcon>
  );
}

export function LoftEventIcon(props: any) {
  let svgpath =
    'M20.6774586,2.90187845 L18.8542541,2.90187845 L18.8542541,1.65745856 C18.8542541,0.742077348 18.1121768,0 17.1967956,0 L17.1304972,0 C16.215116,0 15.4730387,0.742077348 15.4730387,1.65745856 L15.4730387,2.90187845 L6.92055249,2.90187845 L6.92055249,1.65745856 C6.92055249,0.742077348 6.17847514,0 5.26309392,0 L5.19679558,0 C4.28141436,0 3.53933702,0.742077348 3.53933702,1.65745856 L3.53933702,2.90187845 L1.7161326,2.90187845 C0.800751381,2.90187845 0.0586740331,3.6439558 0.0586740331,4.55933702 L0.0586740331,22.3272928 C0.0586740331,23.2428066 0.800751381,23.9847514 1.7161326,23.9847514 L20.6774586,23.9847514 C21.5928398,23.9847514 22.3349171,23.2428066 22.3349171,22.3272928 L22.3349171,4.55933702 C22.3349171,3.6439558 21.5927735,2.90187845 20.6774586,2.90187845 Z M19.5503867,21.3659669 L2.84320442,21.3659669 L2.84320442,7.90740331 L19.5503867,7.90740331 L19.5503867,21.3659669 Z';
  svgpath +=
    'M5.11398895,13.9978343 L7.99783425,13.9978343 C8.14428729,13.9978343 8.26302762,13.8790939 8.26302762,13.7326409 L8.26302762,10.8487956 C8.26302762,10.7023425 8.14428729,10.5836022 7.99783425,10.5836022 L5.11398895,10.5836022 C4.96753591,10.5836022 4.84879558,10.7023425 4.84879558,10.8487956 L4.84879558,13.7326409 C4.84879558,13.8790939 4.96753591,13.9978343 5.11398895,13.9978343 Z';
  svgpath +=
    'M9.75487293,13.9978343 L12.6387182,13.9978343 C12.7851713,13.9978343 12.9039116,13.8790939 12.9039116,13.7326409 L12.9039116,10.8487956 C12.9039116,10.7023425 12.7851713,10.5836022 12.6387182,10.5836022 L9.75487293,10.5836022 C9.60841989,10.5836022 9.48967956,10.7023425 9.48967956,10.8487956 L9.48967956,13.7326409 C9.48967956,13.8790939 9.60841989,13.9978343 9.75487293,13.9978343 Z';
  svgpath +=
    'M14.3957569,13.9978343 L17.2796022,13.9978343 C17.4260552,13.9978343 17.5447956,13.8790939 17.5447956,13.7326409 L17.5447956,10.8487956 C17.5447956,10.7023425 17.4260552,10.5836022 17.2796022,10.5836022 L14.3957569,10.5836022 C14.2493039,10.5836022 14.1305635,10.7023425 14.1305635,10.8487956 L14.1305635,13.7326409 C14.1305635,13.8790939 14.2493039,13.9978343 14.3957569,13.9978343 Z';
  svgpath +=
    'M5.11398895,18.6387182 L7.99783425,18.6387182 C8.14428729,18.6387182 8.26302762,18.5199779 8.26302762,18.3735249 L8.26302762,15.4896796 C8.26302762,15.3432265 8.14428729,15.2244862 7.99783425,15.2244862 L5.11398895,15.2244862 C4.96753591,15.2244862 4.84879558,15.3432265 4.84879558,15.4896796 L4.84879558,18.3735249 C4.84879558,18.5199779 4.96753591,18.6387182 5.11398895,18.6387182 Z';
  svgpath +=
    'M9.75487293,18.6387182 L12.6387182,18.6387182 C12.7851713,18.6387182 12.9039116,18.5199779 12.9039116,18.3735249 L12.9039116,15.4896796 C12.9039116,15.3432265 12.7851713,15.2244862 12.6387182,15.2244862 L9.75487293,15.2244862 C9.60841989,15.2244862 9.48967956,15.3432265 9.48967956,15.4896796 L9.48967956,18.3735249 C9.48967956,18.5199779 9.60841989,18.6387182 9.75487293,18.6387182 Z';
  svgpath +=
    'M14.3957569,18.6387182 L17.2796022,18.6387182 C17.4260552,18.6387182 17.5447956,18.5199779 17.5447956,18.3735249 L17.5447956,15.4896796 C17.5447956,15.3432265 17.4260552,15.2244862 17.2796022,15.2244862 L14.3957569,15.2244862 C14.2493039,15.2244862 14.1305635,15.3432265 14.1305635,15.4896796 L14.1305635,18.3735249 C14.1305635,18.5199779 14.2493039,18.6387182 14.3957569,18.6387182 Z';
  return (
    <SvgIcon {...props}>
      <path d={svgpath}></path>
    </SvgIcon>
  );
}

export function LoftAccessIcon(props: any) {
  const svgpath =
    'M8.66950725,0 L8.66950725,0 C3.88110145,0 0.0144347826,3.86666667 0.0144347826,8.65507246 C0.0144347826,10.3246377 0.547768116,11.9188406 1.2897971,13.2289275 L6.93037681,22.9911884 C7.284,23.6288696 7.99704348,23.9477101 8.66950725,23.9477101 C9.34202899,23.9477101 10.0202319,23.6288696 10.4086377,22.9911884 L16.0492174,13.2347246 C16.7970435,11.9245217 17.3245797,10.3593043 17.3245797,8.66075362 C17.3245797,3.87246377 13.457913,0 8.66950725,0 Z M8.66950725,11.2115942 C6.93037681,11.2115942 5.51008696,9.79130435 5.51008696,8.05217391 C5.51008696,6.31304348 6.93037681,4.89275362 8.66950725,4.89275362 C10.4086377,4.89275362 11.8289275,6.31304348 11.8289275,8.05217391 C11.8289275,9.79710145 10.4086377,11.2115942 8.66950725,11.2115942 Z';
  return (
    <SvgIcon {...props}>
      <path d={svgpath}></path>
    </SvgIcon>
  );
}

export function LoftOthersIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <circle id="oval" cx="2" cy="16" r="2"></circle>
      <circle id="ovalCopy" cx="10" cy="16" r="2"></circle>
      <circle id="ovalCopy2" cx="18" cy="16" r="2"></circle>
    </SvgIcon>
  );
}

export function LoftPathIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
      </svg>
    </SvgIcon>
  );
}
