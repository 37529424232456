// @flow
import type { Slot, SlotType } from '../../type/aae';
import { API } from 'aws-amplify';
import type { Appointment } from '../../type/aae';

export type Appointments = {
  appointments: Appointment[]
};

export type Slots = {
  slots: Slot[]
};

export const AaeApi = {
  getAppointments: (sub: string, type: ?SlotType): Promise<Appointments> => {
    if (type == null) {
      return API.get('user', `/api/v1/user/${sub}/appointments`);
    } else {
      return API.get('user', `/api/v1/user/${sub}/appointments?type=${type}`);
    }
  },

  fetchSlots: (type: ?SlotType): Promise<Slots> => {
    if (type == null) {
      return API.get('loft', '/api/v1/office-hour/slots');
    } else {
      return API.get('loft', `/api/v1/office-hour/slots?type=${type}`);
    }
  },

  callRegisterSlotApi: (
    sub: string,
    time: number,
    detail: string,
    type: SlotType,
    typeVariant: number
  ): Promise<any> => {
    const params = {
      body: {
        time,
        detail,
        type,
        typeVariant
      }
    };
    return API.post('user', `/api/v1/user/${sub}/appointments`, params);
  },

  callEditSlotApi: (
    sub: string,
    time: number,
    detail: string,
    type: SlotType,
    uuid: string
  ): Promise<any> => {
    return API.put('user', `/api/v1/user/${sub}/appointments/${uuid}`, {
      body: {
        time,
        detail,
        type
      }
    });
  },

  callCancelSlotApi: (sub: string, uuid: string): Promise<any> => {
    return API.del('user', `/api/v1/user/${sub}/appointments/${uuid}/`);
  }
};
