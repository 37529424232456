// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import type { SlotStatus } from '../../type/aae';
import { withStyles } from '@material-ui/core/styles';
import { formatReturnToHtml } from '../../utils/FormatString';

type Props = {
  status: SlotStatus,
  onClickFn: () => void,
  time: string,
  type: string,
  eventName: string,
  description: string,
  remain: number,
  isFocused: boolean,
  classes: Object
};

const SlotRemainButton = (props: Props) => {
  const {
    status,
    onClickFn,
    time,
    type = 'aae',
    eventName = '',
    description = '',
    remain = 0,
    isFocused = true,
    classes
  } = props;
  return (
    <Paper className={classes.slot + ' ' + classes[type]}>
      <Grid container justify="flex-start" className={classes.slotHeader}>
        <Grid item xs={9}>
          <Typography
            variant="h4"
            gutterBottom
            align="left"
            className={classes.slotText}
          >
            {eventName}
          </Typography>
        </Grid>
        <Grid item align="right" xs={3}>
          <Typography variant="h4" className={classes.slotText}>
            {status === 'reserved' ? (
              <>{I18n.get('reserve').reserved}</>
            ) : (
              <>
                {I18n.get('reserve').remain} {remain} {I18n.get('reserve').slot}
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="flex-start" className={classes.slotContent}>
        <Grid item xs={8}>
          <Typography
            variant="h1"
            gutterBottom
            align="left"
            className={classes.slotText}
          >
            {time}
          </Typography>
        </Grid>
        {status === 'active' && (
          <Grid item align="right" xs={4}>
            {isFocused ? (
              <Button
                variant="contained"
                color="secondary"
                className={classes.releaseButton}
                onClick={() => {
                  onClickFn();
                }}
              >
                {I18n.get('reserve').release}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                className={classes.chooseButton}
                onClick={() => {
                  onClickFn();
                }}
              >
                {I18n.get('reserve').choose}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      {description !== '' && (
        <Grid container justify="flex-start" className={classes.slotFooter}>
          <Grid item xs={12}>
            <Divider light className={classes.divider} />
          </Grid>
          <Grid item xs={12} className={classes.description}>
            <Typography
              variant="body1"
              align="left"
              className={classes.slotText}
            >
              {formatReturnToHtml(description)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

const styles = theme => ({
  slot: {
    padding: '20px 20px 3px 20px',
    margin: '10px 20px 10px 20px',
    borderRadius: '14px',
    maxWidth: '860px'
  },
  slotHeader: {
    marginBottom: '10px'
  },
  slotContent: {
    marginBottom: '10px'
  },
  slotFooter: {
    marginBottom: '10px'
  },
  chooseButton: {
    borderRadius: '14px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '12px',
    letterSpacing: '2.4px',
    width: '100%',
    height: '30px',
    margin: '-3px 0px 0px 0px',
    maxWidth: '30px'
  },
  releaseButton: {
    borderRadius: '14px',
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '12px',
    letterSpacing: '1px',
    width: '100%',
    height: '30px',
    margin: '-3px 0px 0px 0px',
    maxWidth: '85px'
  },
  divider: {
    margin: '0px 5px 10px 0px',
    backgroundColor: '#fff'
  },
  description: {
    padding: '10px',
    backgroundColor: '#333',
    borderRadius: '14px'
  },
  slotText: {
    color: '#fff'
  },
  aae: {
    background: 'linear-gradient(to right, #11998e, #11667d)'
  },
  custom: {
    background: 'linear-gradient(to right, #bc4e9c, #f80759)'
  }
});

export default withStyles(styles)(SlotRemainButton);
