import React, { useState, useEffect } from 'react';
import Announcement from '../../components/common/Announcement';
import { API } from 'aws-amplify';

export const useAnnouncement = () => {
  const [announcements, setAnnoucements] = useState(['Now Loading...']);
  useEffect(() => {
    let isMounted = true;
    API.get('no_auth_loft', '/api/v1/announcement', {})
      .then(res => {
        if (isMounted) {
          res.announcements && res.announcements.length !== 0
            ? setAnnoucements(res.announcements)
            : setAnnoucements(['なし']);
        }
      })
      .catch(error => {
        console.log(error);
      });
    return () => {
      isMounted = false;
    };
  }, []);
  return { announcements };
};

const AnnouncementContainer = () => {
  return <Announcement {...useAnnouncement()} />;
};

export default AnnouncementContainer;
