// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

const styles = theme => ({
  dialogTitle: {
    fontFamily: 'HiraKakuProN-W6',
    background: '#283C50',
    fontSize: '14px',
    textAlign: 'center'
  },
  dialogTypography: {
    fontFamily: 'HiraKakuProN-W6',
    fontSize: '14px',
    color: '#283C50',
    letterSpacing: '1.2px',
    marginTop: '15px',
    textAlign: 'center'
  },
  dialogButton: {
    margin: theme.spacing.unit
  }
});

type Props = {
  classes: Object,
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  doSignOut: () => void
};

const SignOutDialog = ({
  classes,
  isOpen = false,
  setIsOpen = (isOpen: boolean) => {},
  doSignOut = () => {}
}: Props) => (
  <Dialog
    aria-labelledby="customized-dialog-title"
    open={isOpen}
    fullWidth={true}
  >
    <DialogContent color="secondary">
      <Typography classes={{ root: classes.dialogTypography }}>
        {I18n.get('signOut').wannaSignOut}
      </Typography>
    </DialogContent>
    <DialogActions classes={{ root: classes.dialogButton }}>
      <Button onClick={() => doSignOut()} color="secondary">
        {I18n.get('common').yes}
      </Button>
      <Button onClick={() => setIsOpen(false)} color="secondary">
        {I18n.get('common').no}
      </Button>
    </DialogActions>
  </Dialog>
);

export default withStyles(styles)(SignOutDialog);
