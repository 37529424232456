// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import { ExitToApp } from '@material-ui/icons';
import {
  Divider,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon
} from '@material-ui/core';

const styles = theme => ({
  listItem: {
    height: '54px',
    color: '#fff',
    backgroundColor: theme.palette.background.secondary
  },
  listUnderline: {
    backgroundColor: '#F2941B'
  }
});

type Props = {
  classes: Object,
  setIsOpen: (isOpen: boolean) => void
};

const SignOut = ({ classes, setIsOpen = (isOpen: boolean) => {} }: Props) => (
  <>
    <ListItem
      button
      className={classes.listItem}
      onClick={() => setIsOpen(true)}
    >
      <ListItemIcon>
        <ExitToApp nativeColor={'#fff'} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="h3" style={{ color: '#fff' }}>
            {I18n.get('signOut').signOut}
          </Typography>
        }
        className="signOut"
      />
    </ListItem>
    <Divider component="li" className={classes.listUnderline} />
  </>
);

export default withStyles(styles)(SignOut);
