// @flow
import React from 'react';
import { I18n } from 'aws-amplify';
import {
  Edit,
  Fingerprint,
  LocationOn,
  QuestionAnswer,
  ImportContacts,
  Email,
  Group,
  Https
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { List, Card, CardContent } from '@material-ui/core';
import SignOut from '../../containers/signout/SignOut';
import MenuItem from './MenuItem';

const styles = theme => ({
  root: {
    paddingTop: '10px',
    paddingBottom: '10px',
    height: '100%',
    backgroundColor: theme.palette.background.secondary
  },
  card: {
    marginLeft: '16px',
    marginRight: '16px',
    backgroundColor: '#232F3E'
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0
  }
});

type Props = {
  classes: Object,
  isAuthenticated: boolean
};

const Menu = ({ classes, isAuthenticated = false }: Props) => (
  <div className={classes.root}>
    <List component="nav" className={classes.list}>
      <Card className={classes.card}>
        <CardContent>
          <List component="nav" className={classes.list}>
            {isAuthenticated && (
              <>
                <MenuItem
                  path="/profile"
                  title={I18n.get('otherMenu').editProfile}
                  icon={<Edit nativeColor={'#fff'} />}
                />
                <MenuItem
                  path="/change_password"
                  title={I18n.get('otherMenu').changePassword}
                  icon={<Fingerprint nativeColor={'#fff'} />}
                />
              </>
            )}
            <MenuItem
              path="/access"
              title={I18n.get('otherMenu').access}
              icon={<LocationOn nativeColor={'#fff'} />}
            />
            <MenuItem
              path="https://aws.amazon.com/jp/start-ups/loft/faq/"
              title={I18n.get('otherMenu').faq}
              icon={<QuestionAnswer nativeColor={'#fff'} />}
            />
            <MenuItem
              path="https://s3-ap-northeast-1.amazonaws.com/awslofttokyo-userpolicy-2019/AWS+LOFT+Tokyo%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84.pdf"
              title={I18n.get('otherMenu').loftTerms}
              icon={<ImportContacts nativeColor={'#fff'} />}
            />
            <MenuItem
              path="https://aws.amazon.com/jp/events/terms/"
              title={I18n.get('otherMenu').eventRule}
              icon={<ImportContacts nativeColor={'#fff'} />}
            />
            <MenuItem
              path="https://aws.amazon.com/jp/privacy/"
              title={I18n.get('otherMenu').privacyNotification}
              icon={<Https nativeColor={'#fff'} />}
            />
            <MenuItem
              path="https://aws.amazon.com/jp/codesofconduct/"
              title={I18n.get('otherMenu').communityTerms}
              icon={<Group nativeColor={'#fff'} />}
            />
            <MenuItem
              path="mailto:contact-aws-loft-tokyo@amazon.com"
              title={I18n.get('otherMenu').questions}
              icon={<Email nativeColor={'#fff'} />}
            />
          </List>
          {isAuthenticated ? <SignOut /> : ''}
        </CardContent>
      </Card>
    </List>
  </div>
);

export default withStyles(styles)(Menu);
