// @flow
import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import CheckIn from '../../components/top/CheckIn';
import UserSession from '../../stores/UserSession';

type Props = {
  userSession: UserSession
};

export const useCheckIn = (userSession: UserSession) => {
  const _currentTime = Math.round(Date.now() / 1000);
  const [currentTime, setCurrentTime] = useState(_currentTime);
  let lastUpdateTime = Math.round(_currentTime);
  let interval;
  const updateQRTimestamp = () => {
    const currentTime = Math.round(Date.now() / 1000);
    if (currentTime - lastUpdateTime >= 30) {
      lastUpdateTime = currentTime;
      setCurrentTime(currentTime);
    }
  };

  const qrValue = {
    sub: userSession.sub,
    checkInSignature: userSession.checkInSignature,
    time: currentTime
  };

  useEffect(() => {
    interval = setInterval(updateQRTimestamp, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return { qrValue };
};

const CheckInContainer = inject('userSession')(
  observer(({ userSession }: Props) => {
    return <CheckIn {...useCheckIn(userSession)} />;
  })
);

export default CheckInContainer;
