// @flow
import React from 'react';
import { LoftPathIcon } from '../common/LoftSvgIcon';
import { Link } from 'react-router-dom';
import {
  Divider,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  classes: Object,
  title: string,
  path: string,
  icon: any
};

const styles = theme => ({
  listItem: {
    height: '54px',
    color: '#fff',
    backgroundColor: theme.palette.background.secondary
  },
  listUnderline: {
    backgroundColor: '#F2941B'
  }
});

const listItem = (path, classes, children) => {
  if (path.startsWith('/')) {
    return (
      <>
        <ListItem
          className={classes.listItem}
          component={Link}
          to={path}
          button
        >
          {children}
        </ListItem>
        <Divider component="li" className={classes.listUnderline} />
      </>
    );
  } else {
    return (
      <>
        <ListItem
          className={classes.listItem}
          onClick={event => {
            event.preventDefault();
            if (path.startsWith('mailto:')) {
              window.location = path;
            } else {
              window.open(path);
            }
          }}
          button
        >
          {children}
        </ListItem>
        <Divider component="li" className={classes.listUnderline} />
      </>
    );
  }
};
const MenuItem = ({ classes, title, path, icon }: Props) => {
  return listItem(
    path,
    classes,
    <>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : ''}
      <ListItemText
        primary={
          <Typography style={{ color: '#fff' }} variant="h3">
            {title}
          </Typography>
        }
      />
      {path.startsWith('/') === false && (
        <ListItemSecondaryAction>
          <LoftPathIcon />
        </ListItemSecondaryAction>
      )}
    </>
  );
};

export default withStyles(styles)(MenuItem);
