import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import UserSession from './stores/UserSession';
import TopViewTab from './stores/TopViewTab';
import SignUpFormValue from './stores/SignUpFormValue';
import Root from './containers/root/Root';
import Access from './containers/access/Access';
import Faq from './containers/faq/Faq';
import SignUp from './containers/signup/SignUp';
import ConfirmSignUp from './containers/confirm-signup/ConfirmSignUp';
import SignIn from './containers/signin/SignIn';
import ChangePassword from './containers/change-password/ChangePassword';
import ForgotPassword from './containers/forgot-password/ForgotPassword';
import CompletePassword from './containers/forgot-password/CompletePassword';
import Profile from './containers/profile/Profile';
import ReserveAskAnExpert from './containers/aae/ReserveAskAnExpert';
import NotFound from './containers/error/NotFound';
import { Provider } from 'mobx-react';
import ScrollToTop from './utils/ScrollToTop';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#232F3E',
      dark: '#192534',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ffc551',
      main: '#F2941B',
      dark: '#ba6600',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#64788C', // 現状tabの色
      hint: '#F2941B'
    },
    background: {
      paper: '#FFFFFF',
      secondary: '#232F3E',
      primary: '#192534',
      default: '#FFFFFF'
    },
    action: {
      disabledBackground: '#ba6600'
    }
  },
  typography: {
    useNextVariants: true,
    h1: {
      // signup and confirm signup title
      color: '#283C50',
      fontFamily: 'HiraKakuProN-W6, Meiryo',
      fontWeight: 300,
      fontSize: '24px',
      lineHeight: 1,
      letterSpacing: '1.2px'
    },
    h2: {
      // Welcome's body's title text
      fontFamily: 'HiraKakuProN-W3, Meiryo',
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '1.2px'
    },
    h3: {
      // menu list's title
      fontFamily: 'HiraKakuProN-W3, Meiryo',
      fontSize: '14px',
      color: '#283C50',
      letterSpacing: '0.4px'
    },
    h4: {
      // EventCard's title
      fontFamily: 'HiraKakuProN-W6, Meiryo',
      fontSize: '13px',
      color: '#283C50',
      letterSpacing: '0.4px'
    },
    h5: {
      fontFamily: 'HiraKakuProN-W6, Meiryo',
      fontSize: '14px',
      color: '#FFFFFF',
      letterSpacing: '1.2px'
    },
    h6: {
      fontFamily: 'HiraKakuProN-W6, Meiryo',
      fontSize: '13px',
      color: '#FFFFFF',
      letterSpacing: '1.2px'
    },
    body1: {
      fontFamily: 'HiraKakuProN-W3, Meiryo',
      fontSize: '12px',
      letterSpacing: '0.4px'
    },
    body2: {
      fontFamily: 'HiraKakuProN-W3, Meiryo',
      fontSize: '12px',
      color: '#8CA0B4',
      letterSpacing: '0.4px'
    }
  }
});

class App extends Component {
  render() {
    const userSession = new UserSession();
    const topViewTab = new TopViewTab();
    const signUpFormValue = new SignUpFormValue();
    return (
      <BrowserRouter>
        <ScrollToTop />
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            color="paper"
            autoHideDuration={3500}
            maxSnack={1}
          >
            <Provider
              userSession={userSession}
              topViewTab={topViewTab}
              signUpFormValue={signUpFormValue}
            >
              <Switch>
                <Route exact path="/" component={Root} />
                <Route path="/access" component={Access} />
                <Route path="/faq" component={Faq} />
                <Route path="/signup" component={SignUp} />
                <Route path="/confirm_signup" component={ConfirmSignUp} />
                <Route path="/signin" component={SignIn} />
                <Route path="/change_password" component={ChangePassword} />
                <Route path="/forgot_password" component={ForgotPassword} />
                <Route path="/complete_password" component={CompletePassword} />
                <Route path="/profile" component={Profile} />
                <Route path="/reserve" component={ReserveAskAnExpert} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Provider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;
